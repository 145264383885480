import React from 'react';
import { Box } from '@mui/material';

const Scrollbar = React.forwardRef(({ children, style, ...props }, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: '100%',
        ...style,
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#555',
        }
      }}
      {...props}
    >
      {children}
    </Box>
  );
});

export default Scrollbar;
