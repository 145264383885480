import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
const FastSearch = ({ fastSearch, setFastSearch, defaultSearch, handleDefaultSearch }) => {
    const handleChange = (event) => {
        setFastSearch(event.target.value);
        if (defaultSearch)
            handleDefaultSearch(false)
    };

    return (

<>
    <Box className="drawer-title" sx={{ display: 'flex', alignItems: 'center' }}>
        Fast Search for help guides
        <Tooltip title="
Introducing Fast Search, our state-of-the-art tool designed to swiftly retrieve precise information from a variety of tax return instructions, including:

Individual Tax Return Instructions
Trust Tax Return Instructions
Partnership Tax Return Instructions
Self-Managed Superannuation Fund Annual Return Instructions
Company Tax Return Instructions
Utilizing Azure Search and GPT-4 Omni, Fast Search ensures rapid and accurate responses. The quality of your results improves with the specificity of your queries—more detailed questions lead to better and faster answers. This means you can efficiently navigate complex tax documents and access the exact information you need with unparalleled speed and accuracy.">
            <InfoIcon sx={{ ml: 1 , color:'black'}} />
        </Tooltip>
    </Box>

    <RadioGroup sx={{ py: 1, pl: 2 }} value={fastSearch} onChange={handleChange}>
        <FormControlLabel value="Individual tax return instructions" control={<Radio />} label="Individual tax return instructions" />
        <FormControlLabel value="Trust tax return and instructions" control={<Radio />} label="Trust tax return instructions" />
        <FormControlLabel value="Partnership tax return instructions" control={<Radio />} label="Partnership tax return instructions" />
        <FormControlLabel value="Self-managed superannuation fund annual return instructions" control={<Radio />} label="Self-managed superannuation return instructions" />
        <FormControlLabel value="Company tax return Instructions" control={<Radio />} label="Company Tax Return instructions" />
        <FormControlLabel value="Fringe benefits tax return instructions" control={<Radio />} label="Fringe benefits tax return instructions" />

    </RadioGroup>
</>



    );
};

export default FastSearch;
