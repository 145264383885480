import React, { useEffect, useState } from "react";
import "../../../styles/components/chat/Content.css";
import Introduction from "./Introduction";
import PromptExamples from "./PromptExamples";
import ChatSkeleton from "../../skeleton/ChatSkeleton";
import { useSearchParams } from "react-router-dom";

const ChatContent = ({
  containerRef,
  chatContents,
  isTermsAgree,
  setIsTermsAgree,
  isThinking,
  setChatText,
  setSelectedText
}) => {


  const [searchParams, setSearchParams] = useSearchParams()

  const handleMouseUp = () => {
    const text = window.getSelection().toString();
    setSelectedText(text);
  };

  // const copyToClipboard = () => {
  //   if (selectedText) {
  //     navigator.clipboard.writeText(selectedText).then(() => {
  //       alert('Text copied to clipboard!');
  //     }).catch((err) => {
  //       console.error('Could not copy text: ', err);
  //     });
  //   } else {
  //     alert('Please select some text to copy.');
  //   }
  // };
  return (
    <div ref={containerRef} className="chat-content-container">
      <Introduction
        isTermsAgree={isTermsAgree}
        setIsTermsAgree={setIsTermsAgree}
      />
      {isTermsAgree && chatContents.length === 0 && (
        <>
          {!searchParams.get('fastSearch') &&<div className="prompt-desc">Tax Form Help Guides</div>}
          <PromptExamples
            prompts={[]}
            setChatText={setChatText}
            isTitle={false}
          />
        </>
      )}
      <div onMouseUp={handleMouseUp}>{chatContents} 
          </div>
      {isThinking && <ChatSkeleton />}
    </div>
  );
};

export default ChatContent;
