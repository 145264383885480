import Drawer from "react-modern-drawer";
import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import HierrachyTree from "./HierrachyTree";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { useEffect } from "react";

const HelpGuides = ({ helpGuides, setHelpGuides, helpGuidesOpen, setHelpGuidesOpen }) => {


    const handleModelChange = (event) => {
        setHelpGuides(event.target.value);
    };


    return (



        <>
            <Drawer open={helpGuidesOpen} onClose={setHelpGuidesOpen} direction="right" size="365px" style={{ border: "1px solid #2895EB", top: "100px", height: "auto" }} >

                <Box className="drawer-title">Options</Box>

                {/* <h3 style={{ marginLeft: '15px', marginBottom: '0px' }}>Models</h3> */}
                <RadioGroup
                    sx={{
                        py: 1,
                        pl: 2,
                        display: 'flex',
                        flexDirection: 'row', // To align items horizontally
                        justifyContent: 'flex-start', // To align items at the start
                    }}
                    value={helpGuides}
                    onChange={handleModelChange}
                >
                    <FormControlLabel value="false" control={<Radio />} label="Help Guides" sx={{ mr: 1 }} />
                    <FormControlLabel value="true" control={<Radio />} label={<Box sx={{ display: 'flex', alignItems: 'center' }} >
                        Instruction &nbsp; <Tooltip title="Toggle between Help Guides and Instruction"><InfoIcon sx={{ mr: 1 }} /></Tooltip></Box>} sx={{ mr: 1 }} />
                </RadioGroup>
            </Drawer>
        </>

    );
};

export default HelpGuides;
