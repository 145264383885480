import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/reverse-search/Sidebar/Sidebar";
import { Input } from "@mui/material";
import ChatInput from "../../components/reverse-search/Content/ChatInput";
import ChatContent from "../../components/reverse-search/Content/ChatContent";
import AnswerMessage from "../../components/reverse-search/Content/AnswerMessage";
import { toast } from "react-toastify";
import { publish } from "../../event/event";
import {
  API_BASE_URL,
  ROLE_USER,
  ROLE_ASSISTANT,
  ROLE_KNOWLEDGE,
} from "../../utils/const";
import axios from "axios";
import { SysMessage } from "../../components/reverse-search/Content/SysMessage";
import SummarizedMsg from "../../components/reverse-search/Content/SummarizedMsg";



const ReverseSearch = ({ container, setContainer, selectedPaths,setSelectedText }) => {
  const [chatContents, setChatContents] = useState([]);
  const containerRef = useRef(null);
  const textareaRef = useRef(null);
  const scrollRef=useRef(null)
  const [isThinking, setIsThinking] = useState(false);
  const [chatText, setChatText] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [chatLists, setChatLists] = useState([]);
  const [selectedReferences, setSelectedReferences] = useState({
    text_search_document: [

    ],
    semantic_search_document: [

    ],
    google_search_results:[

    ],
    google_com_search_results:[]
  }

  );

  const newChatClicked = async  ()=>{

    setChatContents([])
    setSelectedReferences({
      text_search_document: [
  
      ],
      semantic_search_document: [
      ],
      google_com_search_results:[

      ],
      google_search_results:[
        
      ]
    })

    await axios.post(`${API_BASE_URL}/reverse_search/create-new-chat`).then((response)=>{
      console.log("New chat created")
    })
  }


  console.log(selectedReferences, "Reverences in Reverse Search")

  const tempFunction = () => {

    console.log("in temp function")
  }

  const getSummary = async (_url, title, source) => {
    setChatContents((prevComponents) => [
      ...prevComponents,
      <SysMessage
        key={`sysMessage1_${new Date().getTime()}`}
        initialStatus={true}
        chatMsg={"Summarizing '" + title + "' ..."}
      />,
    ]);
    const postData = { url: _url, title: title, dataSource: source == "weaviate" ? "ato" : source };
    await axios
      .post(`${API_BASE_URL}/reverse_search/get-summary`, postData)
      .then((response) => {
        const responseKey = `responseBlock_${new Date().getTime()}`;
        const answerKey = `answerMessage_${new Date().getTime()}`;

        setChatContents((prevComponents) => [
          ...prevComponents,
          <React.Fragment key={responseKey}>
            <SummarizedMsg
              key={answerKey}
              answerMsg={{
                msg: response.data.summarization,
              }}
            />
          </React.Fragment>,
        ]);
      })
      .catch((error) => toast.error("Error fetching data:" + error))
      .finally(() => {
        // textareaRef?.current?.focus();
        publish("endLoading");
        hideSysMsgPromise();
      });
  };

  useEffect(()=>{
    axios.get(`${API_BASE_URL}/reverse_search/get-all-chats`).then((response)=>{
      setChatLists(response.data.chats)
    })
  },[])
  const setQuery = ($event) => {
    // Update chatText with the input text
    const updatedText = $event.target.value;

    // Check if the pressed key is "Enter"
    if ($event.key === "Enter" && updatedText!='') {
      setChatText(updatedText);
      $event.preventDefault(); // Prevent the default behavior of form submission
      getResponseFromServer(updatedText);
      $event.target.value = ""; // Clear the input field
    }
  };

  const hideSysMsgPromise = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    publish("hideSysMsg");
  };
  const getResponseForRef = (text) => {
    const postData = {
      prompt: text ?? "tax",
      username: "user",
      selected_search_results: selectedReferences,
      model: "gpt-4o",
    }
    setIsLoading(true)
    axios
      .post(`${API_BASE_URL}/reverse_search/get-query-response`, postData)
      .then((response) => {
        const responseKey = `responseBlock_${new Date().getTime()}`;
        const answerKey = `answerMessage_${new Date().getTime()}`;
        console.log(response.data, "Response");

        setChatContents((prevComponents) => [
          ...prevComponents,
          <React.Fragment key={responseKey}>
            <AnswerMessage
              key={answerKey}
              answerMsg={{
                id: response?.data?.data?.id,
                prompt: text,
                metainfo: response.data.data ? response?.data?.data : response?.data?.message
              }}
              setSelectedText={setSelectedText}
              setChatText={setChatText}
              setSelectedReferences={setSelectedReferences}
              selectedReferences={selectedReferences}
              // getSummary={getSummary}
              // tempFunction={tempFunction}

            />
          </React.Fragment>,
        ]);
        setTimeout(() => {
          if (containerRef.current) {
            containerRef.current.scrollTo({
              top: containerRef.current.scrollHeight,
              behavior: 'smooth'
            });
          }
        }, 100);
      })
      .catch((error) => {
        setIsLoading(false)

        toast.error("Error fetching data:" + error);
      })
      .finally(() => {
        publish("endLoading");
        setIsThinking(false);
        setIsLoading(false)
        textareaRef.current.value=""

      });
  }

  const getResponseFromServer = (text) => {
    setIsThinking(true);

    const postData = {
      prompt: text,
      hierrachy: selectedPaths?.length==0? ["ATO general website"]:selectedPaths,
    };

    axios
      .post(`${API_BASE_URL}/reverse_search/get-search-data`, postData)
      .then((response) => {
        const responseKey = `responseBlock_${new Date().getTime()}`;
        const answerKey = `answerMessage_${new Date().getTime()}`;
        console.log(response.data, "Response");

        setChatContents((prevComponents) => [
          ...prevComponents,
          <React.Fragment key={responseKey}>
            <AnswerMessage
              key={answerKey}
              answerMsg={{
                id: response.data.data.id,
                prompt: text,
                metainfo: response.data.data
              }}
              setChatText={setChatText}
              setSelectedReferences={setSelectedReferences}
              selectedReferences={selectedReferences}
              setSelectedText={setSelectedText}
              getSummary={getSummary}
            />
          </React.Fragment>,
        ]);

        setTimeout(() => {
          if (containerRef.current) {
            containerRef.current.scrollTo({
              top: containerRef.current.scrollHeight,
              behavior: 'smooth'
            });
          }
        }, 100);
      })
      .catch((error) => {
        toast.error("Error fetching data:" + error);
      })
      .finally(() => {
        publish("endLoading");
        setIsThinking(false);
      });
  };

  return (
    <>
      <div className="chat-container">
        <Sidebar container={container} setContainer={setContainer} chatLists={chatLists} newChatClicked={newChatClicked} />

        <div className="main-container reverse-container">
          <span
            style={{ textAlign: "center", width: "100%", marginTop: "35px" }}
          >
            <Input placeholder="Enter query/keywords to build knowledge base " onKeyDown={setQuery} style={{width:"96%",marginTop:"180px"}} />
          </span>
          <ChatContent
            isThinking={isThinking}
            containerRef={containerRef}
            chatContents={chatContents}

          />
          <ChatInput getResponseForRef={getResponseForRef} containerRef={containerRef} textareaRef={textareaRef} isThinking={isLoading} />
        </div>
      </div>
    </>
  );
};

export default ReverseSearch;
