import { Skeleton, Box } from "@mui/material";

const ChatSkeleton = () => {
  return (
    <>
      <Box sx={{ width: "800px", mt: 2 }}>
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "16px" }}
          width="100%"
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "16px" }}
          width="90%"
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "16px" }}
          width="95%"
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "16px" }}
          width="95%"
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "16px", ml: 5 }}
          width="75%"
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "16px", ml: 5 }}
          width="80%"
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "16px", ml: 5 }}
          width="80%"
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "16px", ml: 5 }}
          width="70%"
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "16px" }}
          width="100%"
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "16px" }}
          width="90%"
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
            mt: "10px",
          }}
        >
          <Skeleton variant="circular" width={20} height={20} />
          <Skeleton variant="circular" width={20} height={20} />
          <Skeleton variant="rounded" width={100} height={28} />
        </Box>
      </Box>
    </>
  );
};

export default ChatSkeleton;
