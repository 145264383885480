import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import trees from "../../tree.json"
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MarkdownPreview from "@uiw/react-markdown-preview";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { ReactComponent as Workspaces } from "../../images/workspaces.svg";
import { ReactComponent as ArrowForward } from "../../images/arrow-forward.svg";
import { API_BASE_URL } from "../../utils/const";
import "../../styles/layout/browse/BrowsePage.css";
import BrowseFeedBack from "../../components/browse/Content/BrowseFeedback";
import Sidebar from "../../components/browse/Sidebar/Sidebar";

const BrowsePage = ({ container, setContainer, dataSource, selectedPaths,setSelectedIds, setSelectedPaths,hierarchy,setHierarchy ,setDataSource ,setFastSearch ,setModel, setDefaultSearch, setLegalInfo,setToolTip }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [histories, setHistories] = useState([]);
  const [browseLists, setBrowseLists] = useState([]);
  const [selectedHistory, setSelectedHistory] = useState(0);
  const [currentKeywords, setCurrentKeywords] = useState("");
  const [queryText, setQueryText] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [currentInput, setCurrentInput] = useState("");
  const [error, setError] = useState("");
  const [isWorking, setWorking] = useState(false);
  const [selectedField, setSelectedField] = useState([]);
  const [searchResult, setSearchResult] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [isMoreWorking, setMoreWorking] = useState({});
  const [categoryToTitle, setCategoryToTitle] = useState({});
  const [filterList, setFilterList] = useState({});
  const [expandedStatus, setExpandedStatus] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const categories =
    dataSource !=='google'
      ? [
        "ATO general website",
        "Legislation and supporting material",
        "Public rulings",
        "Practical compliance guidelines",
        "Cases",
        "Decision impact statements",
        "ATO interpretative decisions",
        "Law administration practice statements",
        "Taxpayer alerts",
        // "SMSF Regulator's Bulletins",
        "Other ATO documents",
        "ATO law aids",
      ]
      : ["Google Search"];

  const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: 500,
      "&.Mui-expanded": {
        fontWeight: theme.typography.fontWeightRegular,
      },
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: "var(--tree-view-color)",
      },
      [`& .${treeItemClasses.label}`]: {
        fontWeight: "inherit",
        color: "inherit",
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 10,
      [`& .${treeItemClasses.content}`]: {
        paddingLeft: theme.spacing(2),
      },
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  }));

  const parse_html_tag = (
    text,
    start_tag,
    end_tag = "",
    parse_attribe = true
  ) => {
    if (end_tag === "") {
      if (start_tag[0] === "<") {
        return null;
      }
      end_tag = "</" + start_tag + ">";
      start_tag = "<" + start_tag + " ";
    }

    let start = text.indexOf(start_tag);
    if (start >= 0) {
      start += start_tag.length;
      let end = text.indexOf(end_tag, start);

      if (parse_attribe === false) {
        return {
          content: text.slice(start, end),
          attributes: {},
          next: text.slice(end, text.length),
        };
      }

      let tag_end = text.indexOf(">", start);
      if (tag_end <= 0) {
        return {
          content: text.slice(start, end),
          attributes: {},
          next: text.slice(end, text.length),
        };
      }
      let attr_str = text.slice(start, tag_end);

      let reg_pattern = '([^= ]+)="([^"]+)"';
      reg_pattern = new RegExp(reg_pattern);

      let attrib = {};
      while (true) {
        let result = reg_pattern.exec(attr_str);
        if (result === null) break;
        attrib[result[1]] = result[2];
        attr_str = attr_str.slice(
          result.index + result[0].length,
          attr_str.length
        );
      }
      return {
        content: text.slice(tag_end + 1, end),
        attributes: attrib,
        next: text.slice(end, text.length),
      };
    } else {
      return null;
    }
  };

  const selectNode = (evt, nodeId) => {
    if (evt.target.checked) {
      setSelectedField((oldSelected) => [...oldSelected, nodeId]);
    } else {
      setSelectedField((oldSelected) => {
        let newSelected = oldSelected.filter((v) => v != nodeId);
        return newSelected;
      });
    }
  };

  const reindexTree = (nodes, parents = [], path = "") => {
    return nodes.map((node, index) => {
      const currentId =
        parents && parents.length
          ? `${parents[parents.length - 1]}.${index + 1}`
          : `${index + 1}`;
      const newNode = {
        ...node,
        id: currentId,
        parents: parents,
        path: getLabelTitle(path, node.title),
      };
      const childParent = [...parents, currentId];
  
      if (node.children && node.children.length > 0) {
        newNode.children = reindexTree(node.children, childParent, newNode.path);
      }
      return newNode;
    });
  };

  const getLabelTitle = (parent, title) =>
  parent && parent.length > 0 ? `${parent} > ${title}` : title;
  
  const treeItems = reindexTree(trees);


  useEffect(() => {

    if (!hierarchy) {
      const parents = ["1", "2", "3", "15"];
      setSelectedIds([...parents]);
      const paths = parents.map((parent) => getNodeById(treeItems, parent).path);
      console.log(paths)
      setSelectedPaths([...paths]);
    }
  }, [hierarchy]);

  useEffect(()=>{
    setDataSource("azureS")
    setModel("gpt-4o")
    setHierarchy(false)
    setLegalInfo("false")
    setFastSearch(null)
    setToolTip('false')
    setDefaultSearch(true)
  },[])

  const getNodeById = (nodes, id) => {
    for (const node of nodes) {
      if (node.id === id) {
        return node;
      } else if (node.children && node.children.length) {
        const foundChild = getNodeById(node.children, id);
        if (foundChild) {
          return foundChild;
        }
      }
    }
    return null;
  };

  const StyledTreeItem = (props) => {
    const { nodeId, bgColor, color, labelInfo, labelText, ...other } = props;

    return (
      <StyledTreeItemRoot
        nodeId={nodeId}
        label={
          <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedField.includes(nodeId, 0)}
                  onChange={(evt) => selectNode(evt, nodeId)}
                />
              }
              label={labelText}
            />
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </Box>
        }
        style={{
          "--tree-view-color": color,
          "--tree-view-bg-color": bgColor,
        }}
        {...other}
      />
    );
  };

  StyledTreeItem.propTypes = {
    nodeId: PropTypes.string,
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
  };

  const handleChange = (e) => {
    setCurrentInput(e.target.value);
    setError("");
  };

  const addKeyword = () => {
    if (!currentInput.trim()) {
      setError("Keyword cannot be empty");
      return;
    }
    
    setKeywords([...keywords, { type: 'keyword', value: currentInput.trim() }]);
    
    setCurrentInput("");
    
    updateQueryText([...keywords, { type: 'keyword', value: currentInput.trim() }]);
  };

  const addOperator = (operator) => {
    if (keywords.length === 0) {
      setError("Cannot add operator without a keyword");
      return;
    }
    
    const lastItem = keywords[keywords.length - 1];
    if (lastItem.type === 'operator') {
      setError("Cannot add two operators consecutively");
      return;
    }
    
    setKeywords([...keywords, { type: 'operator', value: operator }]);
    
    updateQueryText([...keywords, { type: 'operator', value: operator }]);
  };

  const removeLastItem = () => {
    if (keywords.length > 0) {
      const updatedKeywords = keywords.slice(0, -1);
      setKeywords(updatedKeywords);
      updateQueryText(updatedKeywords);
    }
  };

  const updateQueryText = (keywordsArray) => {
    const query = keywordsArray.map(item => item.value).join(' ');
    setQueryText(query);
  };

  const clearQuery = () => {
    setKeywords([]);
    setCurrentInput("");
    setQueryText("");
    setError("");
  };

  const clearFields = (ct) => {
    setSelectedField([]);
    setFilterList({});
    showAllResult();
  };

  const showAllResult = () => {
    let titleList = Object.keys(searchResult);
    titleList.map((t) => {
      let sr = searchResult[t];
      sr.show = true;
      setSearchResult({
        ...searchResult,
        [t]: sr,
      });
      return 0;
    });
  };

  const isEqualSet = (a, b) => {
    if (!a || !b) return false;
    if (a.length !== b.length) return false;
    let sa = a.sort();
    let sb = b.sort();
    for (var i = 0; i < a.length; i++) {
      if (sa[i] != sb[i]) return false;
    }
    return true;
  };

  const hideAllResult = () => {
    let titleList = Object.keys(searchResult);
    titleList.map((t) => {
      let sr = searchResult[t];
      sr.show = false;
      setSearchResult({
        ...searchResult,
        [t]: sr,
      });
      return 0;
    });
  };

  const filterFields = async (ct) => {
    let fl = {};
    selectedField.map((e) => {
      let [t, svalue] = categoryToTitle[e];
      if (t) {
        if (fl[t] === undefined) {
          fl[t] = [];
        }
        fl[t].push(svalue);
      }
      return 0;
    });

    let titleList = Object.keys(fl);
    hideAllResult();

    for (var i = 0; i < titleList.length; i++) {
      let t = titleList[i];
      if (isEqualSet(fl[t], filterList[t])) {
        let sr = searchResult[t];
        sr.show = true;
        setSearchResult({
          ...searchResult,
          [t]: sr,
        });

        continue;
      }
      setMoreWorking({
        [t]: true,
      });
      let html = await getSearchResultPerDomain(t, fl[t], 1);
      var patt = /<ol [^<]* total="(.*)">/g;
      var match = patt.exec(html);
      let sr = {};
      sr.content = [html];
      sr.svalue = fl[t];
      sr.number = match ? match[1] : 0;
      sr.show = true;
      setSearchResult({
        ...searchResult,
        [t]: sr,
      });
      setMoreWorking({});
    }

    setFilterList(fl);
  };

  const setCategoryMap = (data) => {
    let item_stack = data.map((e, index) => [
      e,
      `${index + 1}`,
      e.title,
      e.svalue,
    ]);
    let item_stack_len = item_stack.length;
    let result = {};
    for (var i = 0; i < item_stack_len; i++) {
      let [item, nodeId, title, svalue] = item_stack[i];
      result[nodeId] = [title, svalue];
      if (item.child) {
        for (var j = 0; j < item.child.length; j++) {
          let child_item = item.child[j];
          item_stack.push([
            child_item,
            `${nodeId}.${j + 1}`,
            title,
            child_item.svalue,
          ]);
        }
        item_stack_len += item.child.length;
      }
    }
    console.log(result);
    setCategoryToTitle(result);
  };

  const changeCategory = (ct) => {
    setSelectedField([]);
    setFilterList({});
    setCategoryToTitle({});

    let procdata = ct.slice(1, ct.length);
    let [data, _] = getHierachicalData(procdata, 0, 2);
    setCategoryMap(data);
    setCategoryList(data);
  };

  const getSearchResultPerDomain = async (title, svalue_list, start_index) => {
    let curDate = new Date();
    curDate = curDate.toLocaleDateString("en-au", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    let response = await axios.post(`${API_BASE_URL}/browse/legal_db`, {
      query: queryText,
      stype: "find",
      pageSize: 10,
      start: start_index,
      src: "rs",
      pit: curDate,
      df: svalue_list,
    });
    let result_html = response.data.result;
    let result = parse_html_tag(
      result_html,
      '<div id="searchResult">',
      '<div id="filters2">',
      false
    );
    if (result === null) return null;

    let html_txt = result.content;
    html_txt = html_txt.replace('<ol start="1"', `<ol start="${start_index}"`);
    html_txt = html_txt.replace(
      /href="\/law/g,
      'target="_blank" href="https://www.ato.gov.au/law'
    );
    return "<div>" + html_txt;
  };

  const appendData = async (title, svalue_list, start_index) => {
    setMoreWorking({
      [title]: true,
    });
    let html = await getSearchResultPerDomain(title, svalue_list, start_index);
    let sr = searchResult[title];
    sr.content = [...sr.content, html];
    setSearchResult({
      ...searchResult,
      [title]: sr,
    });
    setMoreWorking({});
  };

  const resetQuery = async () => {
    clearQuery();
    setWorking(true);

    await axios({
      method: "post",
      url: `${API_BASE_URL}/browse/get-keywords`,
      data: {
        query: queryText,
      },
    })
      .then(async (response) => {
        const { keywords, history_id } = response.data;
        setCurrentKeywords(keywords);
        setSelectedHistory(history_id);
        setHistories([{ id: history_id, keywords: keywords, prompt: queryText }, ...histories])
        for (var i = 0; i < categories.length; i++) {
          completeQuery(categories[i], keywords, false, history_id);
        }
      })
      .catch((error) => {
        setWorking(false);
        toast.error("Error fetching data:" + error);
      });

    setWorking(false);
  };

  const completeQuery = async (category, keywords, isMore = false, historyId = 0) => {
    if (keywords === "") {
      return;
    }

    var start =
      searchResult[category] !== undefined && isMore
        ? searchResult[category].length
        : 0;

    // Get the plain keywords without operators
    const plainKeywords = keywords.length > 0 
      ? keywords
      : queryText.split(/[&|]/).map(k => k.trim()).filter(k => k && k !== '|').join(' ');

    await axios({
      method: "post",
      url: `${API_BASE_URL}/browse/get-search-data`,
      data: {
        keywords:  queryText.replace(/\s*(&|\|\|)\s*/g, " "),
        // keywords:keywords,
        keywordsadvanced: queryText, // This contains the operators
        pageSize: 10,
        start: start,
        category: category + (dataSource=='weaviate'?"":"&"+dataSource),
        // hierrachy: selectedPaths,
        hierrachy:[
          "ATO general website",
          "Legislation and supporting material",
          "Public rulings",
          "Other",
          "Practical compliance guidelines",
          "Cases",
          "Decision impact statements",
          "ATO interpretative decisions",
          "Law administration practice statements",
          "Taxpayer alerts",
          "Other ATO documents",
          "ATO law aids"
      ],
        historyId: historyId
      },
    })
      .then(async (response) => {
        const result = response.data;

        setSearchResult((prev) => {
          const updatedSearchResult =
            prev[category] && isMore ? prev[category].concat(result) : result;
          return {
            ...prev,
            [category]: updatedSearchResult,
          };
        });

        setMoreWorking({ [category]: false });
      })
      .catch((error) => {
        toast.error("Error fetching data:" + error);
      });
  };

  const getSummary = async (title, url, category, index) => {
    if (searchResult[category][index]["summarization"] !== undefined) return;
    if (searchResult[category][index]["summarizing"] === true) return;
    var sr = searchResult[category];
    sr[index]["summarizing"] = true;

    setSearchResult((prev) => {
      return {
        ...prev,
        [category]: sr,
      };
    });

    axios({
      method: "post",
      url: `${API_BASE_URL}/browse/get-summary`,
      data: {
        url: url,
        title: title,
        dataSource: dataSource=="weaviate"?"ato":dataSource,
      },
    }).then(async (response) => {
      let result = response.data["summarization"];

      sr[index]["summarization"] = result;
      sr[index]["summarizing"] = false;

      setSearchResult((prev) => {
        return {
          ...prev,
          [category]: sr,
        };
      });
      // var tmp = expandedStatus;
      // tmp[key] = true;
      // setExpandedStatus(tmp);
      // setWorking(false);
    });
  };

  const getHierachicalData = (data, start_index, cur_level) => {
    if (data.length === 0) return [null, 0];
    let res = [];
    let ind = start_index;
    let last_data = null;
    while (ind < data.length) {
      let d = data[ind];
      let lv = 0;
      if (d.chapter) {
        lv = d.chapter.split(".").length;
      }
      //console.log(lv);
      if (lv === 0) {
        ind += 1;
        continue;
      }
      if (cur_level === lv) {
        res.push(d);
        last_data = d;
        ind += 1;
        continue;
      }
      if (cur_level > lv) {
        //console.log(res);
        return [res, ind];
      }
      let [child, count] = getHierachicalData(data, ind, lv);
      if (child === null) {
        return [res, ind];
      }
      if (last_data !== null) {
        res[res.length - 1].child = child;
      }
      ind = count;
    }
    //console.log(res);
    return [res, ind];
  };

  const CategoryTreeItem = (item, index) => {
    return (
      <StyledTreeItem
        key={index}
        nodeId={index}
        labelText={item.title}
        labelInfo={item.number}
      >
        {item.child
          ? item.child.map((e, i) => CategoryTreeItem(e, `${index}.${i + 1}`))
          : null}
      </StyledTreeItem>
    );
  };

  const CategoryTreeView = (cat_list) => {
    let data = cat_list;
    return null;
  };

  const handleSummary = (title, url, category, index) => {
    getSummary(title, url, category, index);
  };

  const handleOpen = (url) => {
    window.open(url, "_blank");
  };

  const handleMore = (category, keywords) => {
    if (keywords === "") return;
    setMoreWorking({ [category]: true });
    completeQuery(category, keywords, true, selectedHistory);
  };

  const fetchBrowseList = async () => {
    setIsLoading(true);
    try {
      const ret = await axios.post(`${API_BASE_URL}/browse/get-browse-list`, { username: "user" });
      setHistories(ret.data);
    } catch (e) {
      toast.error("Error fetching data:" + e.message);
    }
    setIsLoading(false);
  }

  const fetchBrowseHistory = async (historyId) => {
    try {
      const ret = await axios.post(`${API_BASE_URL}/browse/get-browse-content`, { username: "user", browse_id: historyId });
      let newData = [];
      for (const history of ret.data) {
        newData[history.category] = newData[history.category] ? [...newData[history.category], ...history.response] : [...history.response];
      }
      setSearchResult(newData);
    } catch (e) {
      toast.error("Error fetching data:" + e.message);
    }
  }

  const handleHistorySelect = (historyId, prompt, keywords) => {
    setSelectedHistory(historyId);
    setQueryText(prompt);
    setCurrentKeywords(keywords);
    fetchBrowseHistory(historyId);
  }

  const refreshBrowseList = (selectedId, historyList) => {
    const list = historyList.map((item) => (
      <React.Fragment key={`browse_history_${item.id}_${new Date().getTime()}`}>
        {item.datetime && (
          <div className="browse-history-date">{item.datetime}</div>
        )}
        <div
          className={
            selectedId !== item.id
              ? "browse-history-body"
              : "browse-history-body active"
          }
        >
          <Link
            className="browse-history-body-link"
            onClick={() => handleHistorySelect(item.id, item.prompt, item.keywords)}
          >
            {item.prompt}
          </Link>
        </div>
      </React.Fragment>
    ));

    setBrowseLists([
      <React.Fragment key="histories">{list}</React.Fragment>,
    ]);
  }

  useEffect(() => {
    fetchBrowseList();
  }, [])

  useEffect(() => {
    refreshBrowseList(selectedHistory, histories);
  }, [selectedHistory, histories])

  return (
    <>
      <Sidebar container={container} setContainer={setContainer} isLoading={isLoading} histories={browseLists} />
      <Box className="browse-container">
        <Container>
          <Typography variant="h6" sx={{ fontWeight: 500, color: '#424242', mb: 2 }}>
            Enter query to search documents across the ATO Database
            <Tooltip 
              title={
                <React.Fragment>
                  <Typography color="inherit" sx={{ fontWeight: 'bold', mb: 1 }}>How to use the Query Builder</Typography>
                  <p>1. Enter keywords in the search field and click "Add Keyword" or press Enter</p>
                  <p>2. Connect keywords with operators:</p>
                  <p>- Use AND (&) to find documents containing both keywords</p>
                  <p>- Use OR (||) to find documents containing either keyword</p>
                  <p>3. Example: "tax deduction" & "small business" || "startup"</p>
                  <p>This will find documents containing both "tax deduction" AND "small business" OR documents containing "startup"</p>
                </React.Fragment>
              } 
              placement="right-start"
              sx={{ maxWidth: 500 }}
            >
              <IconButton size="small" sx={{ ml: 1, color: '#2895eb' }}>
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          
          <Box sx={{ mb: 3, p: 2, backgroundColor: '#f0f8ff', borderRadius: '8px', border: '1px solid #bbdefb' }}>
            <Typography sx={{ fontWeight: 500, mb: 1, color: '#0d47a1' }}>Try these example searches:</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              <Box 
                sx={{ 
                  cursor: 'pointer', 
                  p: '6px 12px', 
                  borderRadius: '16px', 
                  backgroundColor: '#e3f2fd',
                  border: '1px solid #bbdefb',
                  fontSize: '14px',
                  color: '#0d47a1',
                  '&:hover': { backgroundColor: '#bbdefb' }
                }}
                onClick={() => {
                  clearQuery();
                  setKeywords([
                    { type: 'keyword', value: 'capital gains' },
                    { type: 'operator', value: '&' },
                    { type: 'keyword', value: 'property' }
                  ]);
                  updateQueryText([
                    { type: 'keyword', value: 'capital gains' },
                    { type: 'operator', value: '&' },
                    { type: 'keyword', value: 'property' }
                  ]);
                }}
              >
                capital gains & property
              </Box>
              <Box 
                sx={{ 
                  cursor: 'pointer', 
                  p: '6px 12px', 
                  borderRadius: '16px', 
                  backgroundColor: '#e3f2fd',
                  border: '1px solid #bbdefb',
                  fontSize: '14px',
                  color: '#0d47a1',
                  '&:hover': { backgroundColor: '#bbdefb' }
                }}
                onClick={() => {
                  clearQuery();
                  setKeywords([
                    { type: 'keyword', value: 'deduction' },
                    { type: 'operator', value: '||' },
                    { type: 'keyword', value: 'expense' }
                  ]);
                  updateQueryText([
                    { type: 'keyword', value: 'deduction' },
                    { type: 'operator', value: '||' },
                    { type: 'keyword', value: 'expense' }
                  ]);
                }}
              >
                deduction || expense
              </Box>
              <Box 
                sx={{ 
                  cursor: 'pointer', 
                  p: '6px 12px', 
                  borderRadius: '16px', 
                  backgroundColor: '#e3f2fd',
                  border: '1px solid #bbdefb',
                  fontSize: '14px',
                  color: '#0d47a1',
                  '&:hover': { backgroundColor: '#bbdefb' }
                }}
                onClick={() => {
                  clearQuery();
                  setKeywords([
                    { type: 'keyword', value: 'small business' },
                    { type: 'operator', value: '&' },
                    { type: 'keyword', value: 'tax relief' },
                    { type: 'operator', value: '||' },
                    { type: 'keyword', value: 'tax concession' }
                  ]);
                  updateQueryText([
                    { type: 'keyword', value: 'small business' },
                    { type: 'operator', value: '&' },
                    { type: 'keyword', value: 'tax relief' },
                    { type: 'operator', value: '||' },
                    { type: 'keyword', value: 'tax concession' }
                  ]);
                }}
              >
                small business & tax relief || tax concession
              </Box>
            </Box>
          </Box>
          
          <Stack spacing={2} justifyContent="flex-start" alignItems="flex-start">
            {CategoryTreeView(categoryList)}
            <Paper
              component="form"
              onSubmit={(e) => e.preventDefault()}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: 3,
                width: "100%",
                padding: "15px",
                boxShadow: "0 2px 10px rgba(0,0,0,0.08)",
                border: "1px solid #f0f0f0",
              }}
            >
              <Box sx={{ width: '100%', mb: 2 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 500, color: '#424242', mb: 1, display: 'flex', alignItems: 'center' }}>
                  Query Builder
                  <Tooltip 
                    title={
                      <React.Fragment>
                        <p><strong>Keyword:</strong> Any search term you want to find (e.g., "tax return", "deduction")</p>
                        <p><strong>AND (&):</strong> Both terms must appear in results</p>
                        <p><strong>OR (||):</strong> Either term must appear in results</p>
                        <p><strong>Examples:</strong></p>
                        <p>• "personal tax" & "deduction" - Find documents containing both terms</p>
                        <p>• "tax ruling" || "tax determination" - Find documents with either term</p>
                        <p>• "small business" & "tax" || "gst" - Find documents about small business tax or small business GST</p>
                      </React.Fragment>
                    } 
                    placement="top"
                    sx={{ maxWidth: 400 }}
                  >
                    <IconButton size="small" sx={{ ml: 1, color: '#2895eb' }}>
                      <HelpOutlineIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Typography>
                <Typography variant="body2" sx={{ color: '#757575', fontSize: '13px' }}>
                  Build complex searches by combining keywords with AND (&) and OR (||) operators
                </Typography>
              </Box>
              {keywords.length > 0 && (
                <Box sx={{ 
                  display: "flex", 
                  flexWrap: "wrap", 
                  width: "100%", 
                  padding: "8px 12px", 
                  marginBottom: "12px",
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                  minHeight: "46px",
                  backgroundColor: "#fafafa"
                }}>
                  {keywords.map((item, index) => (
                    <Box 
                      key={index} 
                      sx={{ 
                        margin: "3px",
                        padding: "4px 10px",
                        borderRadius: "16px",
                        backgroundColor: item.type === 'keyword' ? '#e3f2fd' : '#f5f5f5',
                        border: item.type === 'keyword' ? '1px solid #bbdefb' : '1px solid #e0e0e0',
                        display: "inline-block",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: item.type === 'keyword' ? '#0d47a1' : '#616161',
                        boxShadow: "0 1px 2px rgba(0,0,0,0.05)"
                      }}
                    >
                      {item.value}
                    </Box>
                  ))}
                </Box>
              )}
              
              {error && (
                <Typography color="error" sx={{ width: "100%", fontSize: "13px", marginBottom: "8px", fontWeight: 500 }}>
                  {error}
                </Typography>
              )}
              
              <Box sx={{ 
                display: "flex", 
                width: "100%", 
                alignItems: "center",
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                overflow: "hidden",
                transition: "all 0.2s ease",
                '&:hover': {
                  borderColor: "#2895eb"
                },
                '&:focus-within': {
                  borderColor: "#2895eb",
                  boxShadow: "0 0 0 2px rgba(40, 149, 235, 0.1)"
                }
              }}>
                <IconButton
                  type="button"
                  sx={{ 
                    p: "12px",
                    color: "#2895eb"
                  }}
                  aria-label="search"
                  onClick={() => {
                    if (keywords.length === 0 && !currentInput.trim()) {
                      setError("Please add at least one keyword to search");
                      return;
                    }
                    
                    if (currentInput.trim()) {
                      const updatedKeywords = [...keywords, { type: 'keyword', value: currentInput.trim() }];
                      setKeywords(updatedKeywords);
                      updateQueryText(updatedKeywords);
                      setCurrentInput("");
                    }
                    
                    setSearchResult({});
                    resetQuery();
                  }}
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ 
                    ml: 1, 
                    flex: 1,
                    fontSize: "15px",
                    '& input': {
                      padding: "10px 0"
                    }
                  }}
                  placeholder="Enter keyword"
                  value={currentInput}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      
                      // If the input is empty and there are no keywords, show error
                      if (!currentInput.trim() && keywords.length === 0) {
                        setError("Please enter a keyword");
                        return;
                      }
                      
                      // If the input is not empty, add it as a keyword
                      if (currentInput.trim()) {
                        addKeyword();
                      }
                      
                      // If we have keywords in our builder, proceed with the search
                      if (keywords.length > 0) {
                        setSearchResult({});
                        resetQuery();
                      }
                    }
                  }}
                />
                {isWorking ? (
                  <CircularProgress size={20} sx={{ mr: 2, color: "#2895eb" }} />
                ) : (
                  <div></div>
                )}
              </Box>
              
              <Box sx={{ 
                display: "flex", 
                width: "100%", 
                justifyContent: "flex-start", 
                marginTop: "15px", 
                gap: "12px",
                flexWrap: "wrap"
              }}>
                <button 
                  type="button"
                  style={{
                    padding: "8px 12px",
                    borderRadius: "6px",
                    border: "1px solid #2895eb",
                    backgroundColor: "#fff",
                    color: "#2895eb",
                    cursor: "pointer",
                    fontWeight: "500",
                    fontSize: "14px",
                    transition: "all 0.2s ease",
                    boxShadow: "0 1px 3px rgba(0,0,0,0.05)"
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = "#f5fbff";
                    e.currentTarget.style.boxShadow = "0 1px 3px rgba(0,0,0,0.1)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = "#fff";
                    e.currentTarget.style.boxShadow = "0 1px 3px rgba(0,0,0,0.05)";
                  }}
                  onClick={addKeyword}
                >
                  Add Keyword
                </button>
                <Tooltip 
                  title="AND operator - Use to find documents containing BOTH keywords" 
                  placement="top"
                >
                <button 
                  type="button"
                  style={{
                    padding: "8px 12px",
                    borderRadius: "6px",
                    border: "1px solid #2895eb",
                    backgroundColor: "#fff",
                    color: "#2895eb",
                    cursor: "pointer",
                    fontWeight: "500",
                    fontSize: "14px",
                    transition: "all 0.2s ease",
                    boxShadow: "0 1px 3px rgba(0,0,0,0.05)"
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = "#f5fbff";
                    e.currentTarget.style.boxShadow = "0 1px 3px rgba(0,0,0,0.1)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = "#fff";
                    e.currentTarget.style.boxShadow = "0 1px 3px rgba(0,0,0,0.05)";
                  }}
                  onClick={() => addOperator("&")}
                >
                  AND (&)
                </button>
                </Tooltip>
                <Tooltip 
                  title="OR operator - Use to find documents containing EITHER keyword" 
                  placement="top"
                >
                <button 
                  type="button"
                  style={{
                    padding: "8px 12px",
                    borderRadius: "6px",
                    border: "1px solid #2895eb",
                    backgroundColor: "#fff",
                    color: "#2895eb",
                    cursor: "pointer",
                    fontWeight: "500",
                    fontSize: "14px",
                    transition: "all 0.2s ease",
                    boxShadow: "0 1px 3px rgba(0,0,0,0.05)"
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = "#f5fbff";
                    e.currentTarget.style.boxShadow = "0 1px 3px rgba(0,0,0,0.1)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = "#fff";
                    e.currentTarget.style.boxShadow = "0 1px 3px rgba(0,0,0,0.05)";
                  }}
                  onClick={() => addOperator("||")}
                >
                  OR (||)
                </button>
                </Tooltip>
                <button 
                  type="button"
                  style={{
                    padding: "8px 12px",
                    borderRadius: "6px",
                    border: "1px solid #f44336",
                    backgroundColor: "#fff",
                    color: "#f44336",
                    cursor: "pointer",
                    fontWeight: "500",
                    fontSize: "14px",
                    transition: "all 0.2s ease",
                    boxShadow: "0 1px 3px rgba(0,0,0,0.05)"
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = "#fff5f5";
                    e.currentTarget.style.boxShadow = "0 1px 3px rgba(0,0,0,0.1)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = "#fff";
                    e.currentTarget.style.boxShadow = "0 1px 3px rgba(0,0,0,0.05)";
                  }}
                  onClick={removeLastItem}
                >
                  Remove Last
                </button>
                <button 
                  type="button"
                  style={{
                    padding: "8px 12px",
                    borderRadius: "6px",
                    border: "1px solid #f44336",
                    backgroundColor: "#fff",
                    color: "#f44336",
                    cursor: "pointer",
                    fontWeight: "500",
                    fontSize: "14px",
                    transition: "all 0.2s ease",
                    boxShadow: "0 1px 3px rgba(0,0,0,0.05)"
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = "#fff5f5";
                    e.currentTarget.style.boxShadow = "0 1px 3px rgba(0,0,0,0.1)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = "#fff";
                    e.currentTarget.style.boxShadow = "0 1px 3px rgba(0,0,0,0.05)";
                  }}
                  onClick={clearQuery}
                >
                  Clear All
                </button>
              </Box>
              
              <Box sx={{ 
                display: "flex", 
                width: "100%", 
                justifyContent: "center", 
                marginTop: "20px"
              }}>
                <button 
                  type="button"
                  style={{
                    padding: "10px 24px",
                    borderRadius: "6px",
                    // backgroundColor: "#2895eb",
                    color: "#fff",
                    cursor: "pointer",
                    fontWeight: "600",
                    fontSize: "15px",
                    transition: "all 0.2s ease",
                    boxShadow: "0 2px 5px rgba(40, 149, 235, 0.3)",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                    backgroundColor:isWorking||keywords.length === 0 ? "gray" : "#2895eb"
                  }}
                  onMouseOver={(e) => {
  if (keywords.length === 0) {
    e.currentTarget.title = "Add some keywords to search";
  }
  // e.currentTarget.style.backgroundColor = "#1a88e0";
  e.currentTarget.style.boxShadow = "0 3px 8px rgba(40, 149, 235, 0.4)";
}}
onMouseOut={(e) => {
  e.currentTarget.title = "";
  // e.currentTarget.style.backgroundColor = "#2895eb";
  e.currentTarget.style.boxShadow = "0 2px 5px rgba(40, 149, 235, 0.3)";
}}                  onClick={() => {
                    if (keywords.length === 0 && !currentInput.trim()) {
                      setError("Please add at least one keyword to search");
                      return;
                    }
                    
                    if (currentInput.trim()) {
                      const updatedKeywords = [...keywords, { type: 'keyword', value: currentInput.trim() }];
                      setKeywords(updatedKeywords);
                      updateQueryText(updatedKeywords);
                      setCurrentInput("");
                    }
                    
                    setSearchResult({});
                    resetQuery();
                  }}
                  disabled={isWorking||keywords.length === 0}
                  // style={{backgroundColor:isWorking||keywords.length === 0 ? "btn-secondary" : ""}}
                >
                  {isWorking ? (
                    <>
                      Searching <CircularProgress size={16} sx={{ color: "#fff" }} />
                    </>
                  ) : (
                    <>
                      Search <SearchIcon fontSize="small" />
                    </>
                  )}
                </button>
              </Box>
            </Paper>
            <Stack sx={{ width: "100%", mb: "40px !important" }}>
              {Object.keys(categories).map((key, index) => {
                return (
                  <Accordion 
                    key={index}
                    sx={{
                      boxShadow: 'none',
                      border: '1px solid #e0e0e0',
                      borderRadius: '8px !important',
                      mb: 1.5,
                      '&:before': {
                        display: 'none',
                      },
                      '&.Mui-expanded': {
                        margin: 0,
                        marginBottom: '12px',
                      }
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: "#2895eb" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        backgroundColor: '#f9f9f9',
                        borderRadius: '8px',
                        '&.Mui-expanded': {
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 0,
                        }
                      }}
                    >
                      <Typography sx={{ 
                        fontSize: "16px", 
                        fontWeight: 500,
                        color: '#424242'
                      }} variant="h6">{`${categories[key]
                        } ${searchResult[categories[key]] === undefined
                          ? ""
                          : "(" + searchResult[categories[key]].length + ")"
                        }`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: '16px' }}>
                      {searchResult[categories[key]]
                        ? searchResult[categories[key]].map((cont, index) => (
                          <Box
                            style={{
                              paddingLeft: "10px",
                              marginBottom: "24px",
                              borderBottom: index < searchResult[categories[key]].length - 1 ? "1px solid #f0f0f0" : "none",
                              paddingBottom: index < searchResult[categories[key]].length - 1 ? "12px" : 0
                            }}
                            key={index}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography sx={{ 
                                fontWeight: 500, 
                                color: '#757575', 
                                marginRight: '8px' 
                              }}>{index + 1}.</Typography>
                              <Typography 
                                color="#2895eb"
                                sx={{ 
                                  fontWeight: 500,
                                  flex: 1
                                }}
                              >
                                {cont["title"]}
                              </Typography>
                              <Tooltip title="Summarize" placement="top">
                                <button
                                  className="browse-reference-button"
                                  style={{
                                    marginLeft: '4px',
                                    transition: 'all 0.2s ease',
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                  onClick={() =>
                                    handleSummary(
                                      cont["title"],
                                      cont["url"],
                                      categories[key],
                                      index
                                    )
                                  }
                                >
                                  <Workspaces />
                                </button>
                              </Tooltip>
                              <Tooltip title={cont["url"]} placement="top">
                                <button
                                  className="browse-reference-button"
                                  style={{
                                    marginLeft: '4px',
                                    transition: 'all 0.2s ease',
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                  onClick={() => handleOpen(cont["url"])}
                                >
                                  <ArrowForward />
                                </button>
                              </Tooltip>
                            </Box>
                            <Typography
                              sx={{ paddingLeft: "24px", color: '#616161', fontSize: '14px', marginTop: '4px' }}
                              variant="body2"
                            >
                              {dataSource === "ato" || dataSource==="weaviate" || dataSource ==="azure" ||
                               dataSource ==="azureS" || dataSource === "azureV"
                                ? cont["hierrachy"]
                                : cont["desc"]}
                            </Typography>
                            {cont["summarization"] != undefined ? (
                              <MarkdownPreview
                                style={{
                                  borderRadius: "10px",
                                  border: "1px solid #D9D9D9",
                                  background: "#FAFAFA",
                                  color: "black",
                                  padding: "24px",
                                  marginTop: "20px",
                                  boxShadow: "0 2px 8px rgba(0,0,0,0.04)"
                                }}
                                source={cont["summarization"]}
                                wrapperElement={{
                                  "data-color-mode": "light",
                                }}
                              ></MarkdownPreview>
                            ) : cont["summarizing"] == true ? (
                              <Typography
                                style={{
                                  paddingTop: "8px",
                                  paddingLeft: "30px",
                                  fontSize: "14px",
                                  color: '#757575',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                Summarizing...{" "}
                                <CircularProgress size={14} sx={{ ml: 2, color: "#2895eb" }} />
                              </Typography>
                            ) : null}
                          </Box>
                        ))
                        : null}
                      {searchResult[categories[key]] &&
                        searchResult[categories[key]].length > 0 && (
                          <Box display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
                            <BrowseFeedBack historyId={selectedHistory} category={categories[key]} />
                            <Box
                              onClick={() =>
                                handleMore(categories[key], currentKeywords)
                              }
                              sx={{
                                cursor: "pointer",
                                transition: "all 0.2s ease",
                                "&:hover": {
                                  transform: "translateY(-1px)"
                                }
                              }}
                            >
                              {isMoreWorking[categories[key]] ? (
                                <CircularProgress size={20} sx={{ ml: 4, mr: 2, color: "#2895eb" }} />
                              ) : (
                                <Typography
                                  variant="button"
                                  color="#2895eb"
                                  display="block"
                                  textAlign="right"
                                  gutterBottom
                                  sx={{ 
                                    pr: 2, 
                                    cursor: "pointer", 
                                    mb: 0,
                                    fontWeight: 500,
                                    fontSize: "14px"
                                  }}
                                >
                                  More
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        )}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default BrowsePage;
