import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../utils/const";
import {
  Button,
  CircularProgress,
  Box,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";

const AOAOptions = [
  {
    label: "Advance",
    value: "0",
  },
  {
    label: "Arrears",
    value: "1",
  },
];

const PeriodOptions = [
  {
    label: "Daily",
    value: "365",
  },
  {
    label: "Weekly",
    value: "52",
  },
  {
    label: "Fortnight",
    value: "26",
  },
  {
    label: "Monthly",
    value: "12",
  },
];

const HPInterestRate = () => {
  const [borrowedAmount, setBorrowedAmount] = useState("");
  const [numberofPayments, setNumberofPayments] = useState("");
  const [repaymentAmount, setRepaymentAmount] = useState("");
  const [firstPay, setFirstPay] = useState(AOAOptions[0].value);
  const [period, setPeriod] = useState(PeriodOptions[0].value);
  const [balloons, setBalloons] = useState([
    { index: 0, period: "", amount: "" },
  ]);
  const [isShowResults, setIsShowResults] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [hpInterestRate, setHPInterestRate] = useState(null);

  const handleFirstPay = (event) => {
    setFirstPay(event.target.value);
  };
  const handleBorrowedAmount = (event) => {
    setBorrowedAmount(event.target.value);
  };
  const handleNumberofPayments = (event) => {
    setNumberofPayments(event.target.value);
  };
  const handleRepaymentAmount = (event) => {
    setRepaymentAmount(event.target.value);
  };
  const handlePeriod = (event) => {
    setPeriod(event.target.value);
  };
  const handleCalculate = async () => {
    if (
      borrowedAmount === "" ||
      numberofPayments === "" ||
      repaymentAmount === ""
    ) {
      toast.warn("Please input the values correctly.");
      return;
    }

    for (const balloon of balloons) {
      if (balloon.amount === "" || balloon.period === "") {
        toast.warn("Please input the values correctly.");
        return;
      }
    }

    setIsCalculating(true);

    const postData = {
      borrowed_amount: borrowedAmount,
      number_of_payments: numberofPayments,
      repayment_amount: repaymentAmount,
      first_payment: firstPay,
      period: period,
      balloons: balloons,
    };

    axios
      .post(`${API_BASE_URL}/chat/get-hp-interest-rate`, postData)
      .then((response) => {
        if (response.data !== "") {
          const rate = Number(response.data).toFixed(3);
          setHPInterestRate(rate);
          setIsShowResults(true);
        }
        setIsCalculating(false);
      })
      .catch((error) => {
        console.error("Error fetching data:" + error);
        setIsCalculating(false);
      });
  };

  const getIndexSuffix = (i) => {
    switch (i) {
      case 0:
        return "st";
      case 1:
        return "nd";
      case 2:
        return "rd";
      default:
        return "th";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        mt: 2,
        maxWidth: "800px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ width: "300px" }}
        >
          Borrowing Amount
        </Typography>
        <TextField
          size="small"
          type="number"
          variant="outlined"
          onChange={handleBorrowedAmount}
          value={borrowedAmount}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ width: "300px" }}
        >
          Number of Payments
        </Typography>
        <TextField
          size="small"
          type="number"
          variant="outlined"
          onChange={handleNumberofPayments}
          value={numberofPayments}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ width: "300px" }}
        >
          Repayment Amount
        </Typography>
        <TextField
          size="small"
          type="number"
          variant="outlined"
          onChange={handleRepaymentAmount}
          value={repaymentAmount}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ width: "300px" }}
        >
          Advance or Arrears
        </Typography>
        <FormControl size="small">
          <Select
            value={firstPay}
            onChange={handleFirstPay}
            sx={{ width: "210px" }}
          >
            {AOAOptions.map((aoaOpt) => (
              <MenuItem value={aoaOpt.value} key={aoaOpt.value}>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  sx={{ lineHeight: 1.5 }}
                >
                  {aoaOpt.label}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ width: "300px" }}
        >
          Period
        </Typography>
        <FormControl size="small">
          <Select
            value={period}
            onChange={handlePeriod}
            sx={{ width: "210px" }}
          >
            {PeriodOptions.map((periodOpt) => (
              <MenuItem value={periodOpt.value} key={periodOpt.value}>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  sx={{ lineHeight: 1.5 }}
                >
                  {periodOpt.label}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {balloons.map((item, index) => (
        <Box sx={{ display: "flex", alignItems: "center" }} key={index}>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ width: "300px" }}
          >
            {item.index + 1}
            {getIndexSuffix(item.index)}
            &nbsp;Balloon if applicable
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            <TextField
              type="number"
              size="small"
              value={balloons[item.index].period}
              variant="outlined"
              onChange={(e) => {
                const tmp = balloons.slice(0);
                tmp[item.index].period = e.target.value;
                if (
                  parseFloat(tmp[tmp.length - 1].period) > 0 &&
                  parseFloat(tmp[tmp.length - 1].amount)
                ) {
                  tmp.push({ index: tmp.length, amount: "0", period: "0" });
                }
                setBalloons(tmp);
              }}
              placeholder="Period"
            />
            <TextField
              type="number"
              size="small"
              value={balloons[item.index].amount}
              variant="outlined"
              onChange={(e) => {
                const tmp = balloons.slice(0);
                tmp[item.index].amount = e.target.value;
                if (
                  parseFloat(tmp[tmp.length - 1].period) > 0 &&
                  parseFloat(tmp[tmp.length - 1].amount)
                ) {
                  tmp.push({ index: tmp.length, amount: "0", period: "0" });
                }
                setBalloons(tmp);
              }}
              placeholder="Amount"
            />
          </Box>
        </Box>
      ))}
      {!isShowResults && (
        <Box sx={{ mt: 2 }}>
          <Button
            className="btn-tax-calculate"
            variant="contained"
            onClick={handleCalculate}
            disabled={isCalculating}
          >
            {isCalculating && <CircularProgress size={20} />}
            Calculate
          </Button>
        </Box>
      )}
      {isShowResults && (
        <Box sx={{ mt: 2 }}>
          <Typography>The HP Interest Rate is {hpInterestRate}%.</Typography>
        </Box>
      )}
    </Box>
  );
};

export default HPInterestRate;
