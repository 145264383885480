import React from 'react';
import { TextField } from '@mui/material';

const NumberField = ({ value, onChange, ...props }) => {
  return (
    <TextField
      type="number"
      value={value}
      onChange={onChange}
      {...props}
      inputProps={{
        step: "0.01", // Customize this if you want specific steps
        min: 0,       // Define minimum value if needed
      }}
    />
  );
};

export default NumberField;
