import React, { useMemo, useEffect, memo } from "react";
import { styled, alpha } from "@mui/material";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { TreeView, TreeItem } from "@mui/x-tree-view";
import { treeItemClasses } from "@mui/x-tree-view";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import trees from "../../tree.json";

const getLabelTitle = (parent, title) =>
  parent && parent.length > 0 ? `${parent} > ${title}` : title;

const reindexTree = (nodes, parents = [], path = "") => {
  return nodes.map((node, index) => {
    const currentId =
      parents && parents.length
        ? `${parents[parents.length - 1]}.${index + 1}`
        : `${index + 1}`;
    const newNode = {
      ...node,
      id: currentId,
      parents: parents,
      path: getLabelTitle(path, node.title),
    };
    const childParent = [...parents, currentId];

    if (node.children && node.children.length > 0) {
      newNode.children = reindexTree(node.children, childParent, newNode.path);
    }
    return newNode;
  });
};

const treeItems = reindexTree(trees);

const HierrachyTree = ({ selectedIds, setSelectedIds, setSelectedPaths, hierarchy }) => {
  const selectedSet = useMemo(() => new Set(selectedIds), [selectedIds]);

  // useEffect(() => {
  //   if (!hierarchy) {
  //     const parents = ["1", "2", "3", "15"];
  //     setSelectedIds([...parents]);
  //     const paths = parents.map((parent) => getNodeById(treeItems, parent).path);
  //     console.log(paths)
  //     setSelectedPaths([...paths]);
  //   }
  // }, [hierarchy]);

  const getNodeById = (nodes, id) => {
    for (const node of nodes) {
      if (node.id === id) {
        return node;
      } else if (node.children && node.children.length) {
        const foundChild = getNodeById(node.children, id);
        if (foundChild) {
          return foundChild;
        }
      }
    }
    return null;
  };

  const getAllChildIds = (parent, ids = []) => {
    if (parent.children) {
      parent.children.forEach((child) => {
        ids.push(child.id);
        getAllChildIds(child, ids);
      });
    }
    return ids;
  };

  const handleChange = (checked, node) => {
    console.log(node, "node")
    let newSeleted = [...selectedIds];
    if (checked) {
      console.log(checked)
      if (node.children) {
        newSeleted = newSeleted.filter(
          (id) => !node.children.some((child) => child.id === id)
        );
      }
      newSeleted = [...newSeleted, node.id];
      for (const parentId of node.parents.slice().reverse()) {
        const parent = getNodeById(treeItems, parentId);
        let updatedSelected = [...newSeleted];
        if (
          parent.children.every((child) => updatedSelected.includes(child.id))
        ) {
          newSeleted = newSeleted.filter(
            (id) => !parent.children.some((child) => child.id === id)
          );
          newSeleted = [...newSeleted, parent.id];
        } else {
          break;
        }
      }
    } else {
      for (const parentId of node.parents) {
        if (newSeleted.includes(parentId)) {
          const parent = getNodeById(treeItems, parentId);
          newSeleted = newSeleted.filter((id) => id !== parent.id);
          newSeleted = [
            ...newSeleted,
            ...parent.children.map((child) => child.id),
          ];
        }
      }
      if (newSeleted.includes(node.id)) {
        newSeleted = newSeleted.filter((id) => id !== node.id);
      }
    }

    console.log(newSeleted)

    setSelectedIds([...newSeleted]);

    const paths = newSeleted.map((id) => getNodeById(treeItems, id).path);

    console.log(paths, "paths")
    setSelectedPaths([...paths]);
  };

  const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: 500,
      "&.Mui-expanded": {
        fontWeight: theme.typography.fontWeightRegular,
      },
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: "var(--tree-view-color)",
      },
      [`& .${treeItemClasses.label}`]: {
        fontWeight: "inherit",
        color: "inherit",
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 10,
      [`& .${treeItemClasses.content}`]: {
        paddingLeft: theme.spacing(2),
      },
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  }));

  const StyledTreeItem = ({ node, ...other }) => {
    const checked =
      selectedSet.has(node.id) ||
      node.parents.some((parent) => selectedSet.has(parent)) ||
      false;
    const indeterminate =
      !checked &&
      (getAllChildIds(node).some((id) => selectedSet.has(id)) || false);

    const disabled = node.id === "15" ? true : false;

    return (
      <StyledTreeItemRoot
        nodeId={node.id}
        label={
          <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ "&.MuiCheckbox-indeterminate svg": { color: "grey" } }}
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={(event) => handleChange(event.target.checked, node)}
                  onClick={(e) => e.stopPropagation()}
                  disabled={disabled}
                />
              }
              label={node.title}
            />
          </Box>
        }
        sx={{
          "--tree-view-bg-color": "white",
          ".MuiTreeItem-content": { borderRadius: 0 },
          ".MuiTreeItem-content:hover": { bgcolor: "rgba(25, 118, 210, 0.12)" },
        }}
        {...other}
      />
    );
  };

  const renderTree = (node) => {
    return (
      <StyledTreeItem key={node.id} node={node}>
        {node.children ? node.children.map((child) => renderTree(child)) : null}
      </StyledTreeItem>
    );
  };

  return (
    <TreeView
      className="treeview-data-source"
      aria-label="options"
      defaultCollapseIcon={<ArrowDropDown />}
      defaultExpandIcon={<ArrowRight />}
      defaultEndIcon={<div style={{ width: 124 }} />}
      selected={selectedSet}
      multiSelect
    >
      {treeItems.map((node) => renderTree(node))}
    </TreeView>
  );
};

export default memo(HierrachyTree);
