import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { DatePicker } from "@mui/x-date-pickers";
import { API_BASE_URL } from "../../utils/const";
import axios from "axios";
import moment from "moment";


const LoanDetailsComponent = () => {
  const [loanYear, setLoanYear] = useState("");
  const [fullTerm, setFullTerm] = useState("");
  const [openingBalance, setOpeningBalance] = useState("");
  const [openingBalanceYear, setOpeningBalanceYear] = useState("");
  const [lodgementDate, setLodgementDate] = useState(null);
  const [repayments, setRepayments] = useState([
    { index: 0, repayment_date: null, repayment_amount: "" },
    { index: 1, repayment_date: null, repayment_amount: "" },
    { index: 2, repayment_date: null, repayment_amount: "" },
  ]);
  const [isCalculating, setIsCalculating] = useState(false);
  const [isShowResults, setIsShowResults] = useState(false);
  const [calculatedValue, setCalculatedValue] = useState(null);

  const handleLoanYear = (event) => {
    setLoanYear(event.target.value);
  };
  const handleFullTerm = (event) => {
    setFullTerm(event.target.value);
  };
  const handleOpeningBalance = (event) => {
    setOpeningBalance(event.target.value);
  };
  const handleOpeningBalanceYear = (event) => {
    setOpeningBalanceYear(event.target.value);
  };
  const handleLodgementDate = (newDate) => {
    setLodgementDate(newDate);
  };

  const handleCalculate = () => {
    // Validate that the first repayment details and lodgement date are filled out
    if (
      loanYear === "" ||
      fullTerm === "" ||
      openingBalance === "" ||
      openingBalanceYear === "" ||
      lodgementDate === null ||
      repayments[0].repayment_date === null ||
      repayments[0].repayment_amount === ""
    ) {
      toast.warn(
        "Please fill out all required fields, including the first repayment and lodgement date."
      );
      return;
    }

    setIsCalculating(true);

    // Separate repayment dates and repayment amounts into two arrays
    const repaymentDates = repayments
      .filter((repayment) => repayment.repayment_date !== null)
      .map((repayment) =>  moment(repayment.repayment_date).format("YYYY-MM-DD"));

    const repaymentAmounts = repayments
      .filter((repayment) => repayment.repayment_amount !== "")
      .map((repayment) => repayment.repayment_amount);

    const postData = {
      loan_year: loanYear,
      full_term: fullTerm,
      opening_balance: openingBalance,
      opening_balance_year: openingBalanceYear,
      lodgement_date:  moment(lodgementDate).format("YYYY-MM-DD") ,
      repayment_dates: repaymentDates, // Array of repayment dates
      repayment_amounts: repaymentAmounts, // Array of repayment amounts
    };

    // Simulate API request
    axios
      .post(`${API_BASE_URL}/chat/cal_div_7a`, postData)
      .then((response) => {
        if (response.data) {
          const retVal = response.data.report;
          setCalculatedValue(retVal);
          setIsShowResults(true);
        }
        setIsCalculating(false);
      })
      .catch((error) => {
        toast.error("Error fetching data:" + error);
        setIsCalculating(false);
      });
  };

  const getIndexSuffix = (i) => {
    switch (i) {
      case 0:
        return "st";
      case 1:
        return "nd";
      case 2:
        return "rd";
      default:
        return "th";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: 2,
        maxWidth: "800px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ width: "300px" }}>Loan Year</Typography>
        <TextField
          size="small"
          type="number"
          variant="outlined"
          onChange={handleLoanYear}
          value={loanYear}
        />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ width: "300px" }}>Full Term</Typography>
        <TextField
          size="small"
          type="number"
          variant="outlined"
          onChange={handleFullTerm}
          value={fullTerm}
        />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ width: "300px" }}>Opening Balance</Typography>
        <TextField
          size="small"
          type="number"
          variant="outlined"
          onChange={handleOpeningBalance}
          value={openingBalance}
        />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ width: "300px" }}>Opening Balance Year</Typography>
        <TextField
          size="small"
          type="number"
          variant="outlined"
          onChange={handleOpeningBalanceYear}
          value={openingBalanceYear}
        />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ width: "300px" }}>Lodgement Date</Typography>
        <DatePicker
          value={lodgementDate}
          onChange={handleLodgementDate}
          renderInput={(params) => <TextField {...params} />}
          format="DD/MM/YYYY"
        />
      </Box>

      {repayments.map((item, index) => (
        <Box sx={{ display: "flex", alignItems: "center" }} key={index}>
          <Typography sx={{ width: "300px" }}>
            {item.index + 1}
            {getIndexSuffix(item.index)} Repayment
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            <DatePicker
              value={repayments[item.index].repayment_date}
              format="DD/MM/YYYY"
              onChange={(newDate) => {
                const tmp = repayments.slice();
                tmp[item.index].repayment_date = newDate;
                setRepayments(tmp);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Repayment Date" />
              )}
            />
            <TextField
              type="number"
              size="small"
              value={repayments[item.index].repayment_amount}
              onChange={(e) => {
                const tmp = repayments.slice();
                tmp[item.index].repayment_amount = e.target.value;
                setRepayments(tmp);
              }}
              placeholder="Repayment Amount"
            />
          </Box>
        </Box>
      ))}

      {!isShowResults && (
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            onClick={handleCalculate}
            disabled={isCalculating}
          >
            {isCalculating && <CircularProgress size={20} />}
            Calculate
          </Button>
        </Box>
      )}

      {isShowResults && (
        <Box sx={{ mt: 2 }}>
          <Typography>Your Report is available at link below
            <div>
            <a target="_blank" href={calculatedValue}>{calculatedValue}</a>
            </div>
             </Typography>
        </Box>
      )}
    </Box>
  );
};

export default LoanDetailsComponent;
