import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import "../../../styles/components/chat/ChatInput.css";
import { ArrowForward } from "@mui/icons-material";
// import { ReactComponent as Stop } from "../../../images/stop-circle-bold.svg";
import { CircularProgress } from "@mui/material";
import axios from "axios";

const ChatInput = ({ chatId, link, setLinkCopied, fetchCode, isTermsAgree, textareaRef, chatText, isThinking, canEdit, handleChatInputKeyDown, handleChatTextChange, sendClicked, learnClicked, isSendBtnActive, isLearnActivez, canChat }) => {




  return (
    <>
      {canChat?<div className="chat-input">
        <div className="chat-input-box" >
          <TextareaAutosize rows={1} minRows={1} maxRows={9} className="chat-input-textarea-field" placeholder="Ask Tax Genii anything......" ref={textareaRef} value={chatText} onKeyDown={handleChatInputKeyDown} onChange={handleChatTextChange} disabled={!isTermsAgree} />
        </div>
        {!isThinking && (
          <button className="chat-input-button-send" onClick={sendClicked} disabled={!isTermsAgree}>
            <ArrowForward />
          </button>
        )}
        <button className="share-chat" onClick={fetchCode} disabled={!chatId || link}>
          {!link ? "Share Chat" : "Link Copied"}
        </button>
        {isThinking && (
          <button className="chat-input-button-send">
            <CircularProgress color="primary" size={30} sx={{ svg: { width: "30px", height: "30px" } }} />
          </button>
        )}
      </div>:""}
    </>
  );
};

export default ChatInput;
