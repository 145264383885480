import { useSearchParams } from "react-router-dom";

const samplePrompts = [
  "Company tax return instructions",
  "Trust tax return instructions",
  "Partnership tax return instructions",
  "Individual tax return instructions",
  "Fringe benefits tax return instructions",
];

const PromptExamples = ({ prompts, setChatText, isTitle = true }) => {

  const [searchParams, setSearchParams] = useSearchParams()
  const questions = prompts && prompts.length > 0 ? prompts : samplePrompts;

  const handleAsk = (msg) => {
    setChatText(msg);
  };

  return (
    <>{!searchParams.get('chatId') && (<div className="prompt-examples-container">
      {isTitle && (
        <div className="prompt-examples-title">Related Questions</div>
      )}
      <div className="prompt-examples">
        {questions?.map((msg, index) => (
          <div
            key={index}
            className="prompt-example"
            onClick={() => handleAsk(msg)}
          >
            {msg}
          </div>
        ))}
      </div>
    </div>)}</>
  );
};

export default PromptExamples;
