import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useSearchParams } from "react-router-dom";
import { publish } from "../../event/event";

import "react-toastify/dist/ReactToastify.css";
import "react-modern-drawer/dist/index.css";

import "../../styles/global.css";
import "../../styles/layout/chat/ChatPage.css";

import Sidebar from "../../components/chat/Sidebar/Sidebar";
import ChatContent from "../../components/chat/Content/ChatContent";
import ChatInput from "../../components/chat/Content/ChatInput";

import AnswerMessage from "../../components/chat/Content/AnswerMessage";
import { SysMessage } from "../../components/chat/Content/SysMessage";
import SummarizedMsg from "../../components/chat/Content/SummarizedMsg";
import trees from "../../tree.json";

import {
  API_BASE_URL,
  ROLE_USER,
  ROLE_ASSISTANT,
  ROLE_KNOWLEDGE,
} from "../../utils/const";

const ChatPage = ({ container, setContainer, dataSource, selectedPaths, model, hierarchy, legalInfo, setSelectedText, fastSearch,toolTip, setSelectedIds, setSelectedPaths, setHierarchy ,setDataSource ,setFastSearch ,setModel, setDefaultSearch, setLegalInfo, setToolTip, helpGuides }) => {
  const textareaRef = useRef(null);
  const containerRef = useRef(null);
  const chatlistcontainerRef = useRef(null);

  const termsAgree = localStorage.getItem("isTermsAgree");
  const [isTermsAgree, setIsTermsAgree] = useState(
    termsAgree ? termsAgree : false
  );
  const [chatTitle, setChatTitle] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [canEdit, setCanEdit] = useState(true);
  const [chatText, setChatText] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatLists, setChatLists] = useState([]);
  const [chatContents, setChatContents] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);

  const [isThinking, setIsThinking] = useState(false);
  const [isLearnActive, setIsLearnActive] = useState(false);
  const [isSendBtnActive, setIsSendBtnActive] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [link,setLinkCopied] = useState(false)
  const [canChat, setCanChat] = useState(true)

  const [promptLink, setPromptLink] = useState(null);

  const urlMap = {
    "Company tax return instructions": "/?fastSearch=Company tax return Instructions",
    "Trust tax return instructions": "/?fastSearch=Trust tax return and instructions",
    "Partnership tax return instructions": "/?fastSearch=Partnership tax return instruction",
    "Individual tax return instructions": "/?fastSearch=Individual tax return instructions",
    "Fringe benefits tax return instructions": "/?fastSearch=Fringe benefits tax return instructions",
    "Self-managed superannuation return instructions": "/?fastSearch=Self-managed superannuation fund annual return instructions"
  };

  // Get a specific query parameter

  useEffect(() => {

    console.log(selectedChat, "Selected Chat")
    // setSelectedChat(null)
    if (!searchParams.get('chatId')) {
      newChatClicked()
      const postData = { username: "user", fastSearch:null };

      if(searchParams.get('fastSearch')){
        postData.fastSearch=searchParams.get('fastSearch')
      }
      getChatListFromSever(postData);
    }
    
  }, []);

  useEffect(() => {
    containerRef.current.scrollTo({
      top: containerRef.current.scrollHeight,
      behavior: 'smooth'
    });
    if (
      chatContents.length == 0 ||
      selectedChat == 0 ||
      (chatContents.length == 1 && chatContents[0].props.children.length == 0)
    ) {
      setChatTitle("New chat");
    }
  }, [chatContents]);

  useEffect(() => {
    console.log("Hello use Effect in")
    refreshChatList();
  }, [selectedChat, messages]);

  useEffect(() => {
    if ((selectedChat == 0 || selectedChat == null ) && !searchParams.get('chatId')) {
      return;
    }
    console.log("Chat Id", searchParams.get('chatId') )
    const postData = { username: "user", chat_id: selectedChat?selectedChat:searchParams.get('chatId') };
    getChatContentFromServer(postData);
  }, [selectedChat]);

  useEffect(() => {
    if (isTermsAgree) {
      localStorage.setItem("isTermsAgree", true);
    }
  }, [isTermsAgree]);

  // useEffect(() => {
  //   chatlistcontainerRef.current.scrollTop = chatlistcontainerRef.current.scrollHeight;
  // }, [chatLists]);

  const sidebar = useCallback (()=>{

    console.log("hello sidebar")
    console.log(container, chatlistcontainerRef)
    console.log("is loading", isLoading)

    return <Sidebar
    container={container}
    setContainer={setContainer}
    chatlistcontainerRef={chatlistcontainerRef}
    chatLists={chatLists}
    newChatClicked={newChatClicked}
    isLoading={isLoading}
  />
  },[chatLists])

  useEffect ( ()=>{
    console.log(isLoading)

  },[isLoading])


  useEffect (()=>{

    console.log("Component Loaded")
  },[])

  const refreshChatList = () => {
    
    console.log("hello")
    const newChatLists = messages.map((item) => (
      <React.Fragment key={`chatList_${item.id}}`}>
        {item.datetime && (
          <div className="chat-history-date">{item.datetime}</div>
        )}
        <div
          className={
            selectedChat != item.id
              ? "chat-history-body"
              : "chat-history-body active"
          }
        >
          <span
            className="chat-history-body-link"
            onClick={() => chatSelected(item.id, item.title)}
          >
            {item.title}
          </span>
          {/* <button className="button-del" onClick={() => delClicked(item.id, item.title)}>
            <FontAwesomeIcon icon={faTrashCan} size="lg" />
          </button> */}
        </div>
      </React.Fragment>
    ));

    setChatLists([
      <React.Fragment key="chatLists">{newChatLists}</React.Fragment>,
    ]);
  };

  useEffect(()=>{

    console.log(chatLists, "Chat Lists")

  },[chatLists])

  const getSummary = async (_url, title, source) => {
    setChatContents((prevComponents) => [
      ...prevComponents,
      <SysMessage
        key={`sysMessage1_${new Date().getTime()}`}
        initialStatus={true}
        chatMsg={"Summarizing '" + title + "' ..."}
      />,
    ]);
    const postData = { url: _url, title: title, dataSource: source == "weaviate" ? "ato" : source };
    await axios
      .post(`${API_BASE_URL}/chat/get-summary`, postData)
      .then((response) => {
        const responseKey = `responseBlock_${new Date().getTime()}`;
        const answerKey = `answerMessage_${new Date().getTime()}`;

        setChatContents((prevComponents) => [
          ...prevComponents,
          <React.Fragment key={responseKey}>
            <SummarizedMsg
              key={answerKey}
              answerMsg={{
                msg: response.data.summarization,
              }}
              getSummary={getSummary}
            />
          </React.Fragment>,
        ]);
      })
      .catch((error) => toast.error("Error fetching data:" + error))
      .finally(() => {
        textareaRef?.current?.focus();
        publish("endLoading");
        hideSysMsgPromise();
      });
  };

  const fetchCode = async ()=>{

    setLinkCopied(true)
    await axios
    .post(`${API_BASE_URL}/chat/get-chat-link`, {chatId:selectedChat})
    .then((response) => {
      copyCode(response?.data?.link)

    })
    .catch((error) => toast.error("Error fetching data:" + error))
    .finally(() => {
      textareaRef?.current?.focus();
      publish("endLoading");
      hideSysMsgPromise();
    });
  }

  const copyCode = async (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Link copied to clipboard!');

      setTimeout(()=>{
        setLinkCopied(false)
      },4000)
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  }
  const getChatListFromSever = async (postData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}/chat/get-chat-list`,
        postData
      );
      setCanEdit(true);
      const activeId = response.data.active_id;

      setMessages(response.data.message);
      // setSelectedChat(activeId);

      //refreshChatList(response, activeId);
      textareaRef?.current?.focus();
      setIsLoading(false);
    } catch (error) {
      toast.error("Error fetching chat list: " + error);
    }
  };

  const getChatContentFromServer = async (postData) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/chat/get-chat-content`,
        postData
      );
      let messageString = response.data.message;
      setCanEdit(false);
      const listChatContents = messageString.map((item) => (
        <React.Fragment key={`chatContent_${item?.id}_${new Date().getTime()}`}>
          {item?.role === ROLE_USER || item?.role === ROLE_KNOWLEDGE ? (
            <div className="chat-content-user">{item?.prompt}</div>
          ) : item.role === ROLE_ASSISTANT ? (
            <AnswerMessage
              answerMsg={{
                id: item?.id,
                prompt: item?.prompt,
                metainfo: item?.metainfo,
              }}
              setChatText={setChatText}
              getSummary={getSummary}
            />
          ) : (
            <></>
          )}
        </React.Fragment>
      ));
      setChatContents([
        <React.Fragment key="ChatContents">{listChatContents}</React.Fragment>,
      ]);
      textareaRef?.current?.focus();
    } catch (error) {
      toast.error("Error fetching chat list: " + error);
    }
  };

  const handleChatInputKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendClicked();
    }
  };

  const process_table = useCallback((text) => {
    if (!text.includes('\t')) return text;
    
    let lines = text.split("\n");
    lines = lines.map((e) => e.split("\t"));
    let i = 0;
    while (i < lines.length) {
      let ll = lines[i];

      if (ll.length === 1) {
        lines[i] = lines[i][0];
        i += 1;
        continue;
      }
      let j = i + 1;
      while (j < lines.length) {
        if (lines[j].length !== ll.length) break;
        j += 1;
      }
      if (j > i + 1) {
        let cnt = ll.length;
        lines[i] = "| " + lines[i].join(" | ") + " |\n";
        lines[i] += "| " + "--- | ".repeat(cnt);
        for (i = i + 1; i < j; i++) {
          lines[i] = "| " + lines[i].join(" | ") + " |";
        }
        continue;
      } else {
        lines[i] = lines[i].join("\t");
        i += 1;
        continue;
      }
    }
    return lines.join("\n");
  }, []);

  const handleChatTextChange = (event) => {
    // Only process as table if it contains tab characters
    const text = event.target.value;
    const processedText = text.includes('\t') ? process_table(text) : text;
    
    setChatText(processedText);
    setIsSendBtnActive(text !== "");
    
    // Move prompt link check to a separate effect
    if (text === "") {
      setPromptLink(null);
    } else {
      // Check for match anywhere in the input
      const inputValue = text.toLowerCase();
      const matchingKey = Object.keys(urlMap).find(key => {
        const keyFirstWord = key.toLowerCase().split(' ')[0];
        return inputValue.includes(keyFirstWord);
      });

      setPromptLink(matchingKey ? { text: matchingKey, url: urlMap[matchingKey] } : null);
    }
  };

  const sendClicked = () => {
    let isNewChat = false;

    if (chatText === "") {
      return;
    }

    if (
      chatLists.length === 0 ||
      selectedChat === 0 ||
      (chatLists.length === 1 && chatLists[0].props.children.length == 0)
    )
      isNewChat = true;

    // Api Call
    const newFragments = chatText.split("\n").map((line, index) => (
      <div key={`userMessage_${index}`}>
        {line}
        <br />
      </div>
    ));

    setChatContents((prevComponents) => [
      ...prevComponents,
      <React.Fragment key={`userMessage_${new Date().getTime()}`}>
        <div className="chat-content-user">{newFragments}</div>
      </React.Fragment>,
    ]);
    setIsSendBtnActive(false);
    getResponseInformationFromServer(isNewChat);
    setChatText("");
    textareaRef.current.value=""

    setIsThinking(true);
  };

  const getResponseInformationFromServer = (isNewChat, message = "") => {
    console.log(dataSource, "Data Source")
    console.log(selectedPaths)
    const postData = {
      username: "user",
      prompt: message === "" ? chatText : message,
      learn: isLearnActive,
      dataSource: dataSource == "weaviate" ? "ato" : dataSource,
      hierrachy: hierarchy == false ? selectedPaths : ["Company tax return instructions 2023"],
      model: model,
      toolTip:toolTip==null || toolTip=='false'? false:true,
      legalInfo: legalInfo=='true'?true:false,
      fastSearch:fastSearch,
      helpGuides: helpGuides=="true"?true:false
    };
    axios
      .post(`${API_BASE_URL}/chat/get-search-data`, postData)
      .then((response) => {
        publish("endLoading");

        if (response.data.message === "success") {
          getResponseFromServer(isNewChat);
        }
      })
      .catch((error) => {
        toast.error("Error fetching data:" + error);
        publish("endLoading");
        setIsThinking(false);
      })
      .finally(() => {
        setIsLearnActive(false);
      });
  };

  const getResponseFromServer = async () => {
    const postData = {
      username: "user",
      prompt: chatText,
      learn: isLearnActive,
      hierarchy: selectedPaths,
      stream: true,
    };
  
    try {
      // Show loading only until we start receiving the stream
      setIsThinking(true);
      
      const response = await fetch(`${API_BASE_URL}/chat/get-response-message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "text/event-stream",
          "Cache-Control": "no-cache",
          "Connection": "keep-alive",
        },
        body: JSON.stringify(postData),
      });
  
      if (!response.ok) throw new Error("Error fetching data");
  
      // Once we have a response, we can hide the loading indicator
      setIsThinking(false);
      
        // Extract headers
  let chatId;
  let metaInfo;
  let isNewChat;
  let parsedMetaInfo ={}

      
      // // Create a unique ID for this streaming message
      // const streamingId = `streaming_${Date.now()}`;
      
      // Add an initial empty message to the chat
      setChatContents((prevComponents) => [
        ...prevComponents,
        <React.Fragment >
          <AnswerMessage
            answerMsg={{
              id: chatId ,
              prompt: "",
              metainfo: parsedMetaInfo,
            }}
            setChatText={setChatText}
            getSummary={getSummary}
          />
        </React.Fragment>,
      ]);
  
      const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
      
      let accumulatedMessage = "";
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          chatId = response.headers.get("X-chat-Id");
          metaInfo = response.headers.get("X-metainfo");
          isNewChat = response.headers.get("X-New-Chat") === "true";
          parsedMetaInfo = metaInfo ? JSON.parse(metaInfo) : {};
          break;}
        
        console.log("chunk: ", value);
        accumulatedMessage += value;
        
        // Here's the key change: Instead of re-rendering components,
        // we simply update the existing message with new content
        setChatContents((prevComponents) => {
          // Create a deep copy of the previous components
          return prevComponents.map((component, index) => {
            // Only update the last component (our streaming message)
            if (index === prevComponents.length - 1) {
              return (
                <React.Fragment>
                  <AnswerMessage
                    answerMsg={{
                      id: chatId,
                      prompt: accumulatedMessage,
                      metainfo: parsedMetaInfo,
                    }}
                    setChatText={setChatText}
                    getSummary={getSummary}
                  />
                </React.Fragment>
              );
            }
            return component;
          });
        });
      }

        // When done, add any final updates (like changing the ID from streaming to final)
      setChatContents((prevComponents) => {
        return prevComponents.map((component, index) => {
          if (index === prevComponents.length - 1) {
            return (
              <React.Fragment key={chatId}>
                <AnswerMessage
                  answerMsg={{
                    id: chatId,
                    prompt: accumulatedMessage,
                    metainfo: parsedMetaInfo,
                  }}
                  setChatText={setChatText}
                  getSummary={getSummary}
                />
              </React.Fragment>
            );
          }
          return component;
        });
      });
  
      if (isNewChat) {
        const newMessage = {
          id: chatId || "new_chat_id_placeholder",
          title: "New Chat",
        };
        messages.unshift(newMessage);
        setSelectedChat(chatId || "new_chat_id_placeholder");
      }
  
      hideSysMsgPromise();
    } catch (error) {
      toast.error("Error fetching data: " + error.message);
    } finally {
      textareaRef?.current?.focus();
      // Only need to publish endLoading here if it hasn't been done already
      publish("endLoading");
      // Make sure isThinking is false in any case
      setIsThinking(false);
    }
  };
  
  

  const hideSysMsgPromise = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    publish("hideSysMsg");
  };

  const chatSelected = (chat_id, chat_title) => {
    setSelectedChat(chat_id);
    setChatTitle(chat_title);
    setCanChat(false)
  };

  const delClicked = (chat_id, chat_title) => {
    const postData = { username: "user", chat_id: chat_id };
    axios
      .post(`${API_BASE_URL}/chat/delete-chat`, postData)
      .then((response) => {
        setChatContents([]);
        getChatListFromSever(postData);

        toast.success(`Chat history  '${chat_title}'  removed.`);
      })
      .catch((error) => toast.error("Error fetching data:" + error));
  };

  const learnClicked = () => {
    setIsLearnActive(isLearnActive ? false : true);
  };

  const newChatClicked = () => {
    setChatContents([]);
    const postData = { username: "user" };
    axios
      .post(`${API_BASE_URL}/chat/create-new-chat`, postData)
      .then((response) => {
        if (response.data.message === "success") {
          setSelectedChat(0);
          setCanChat(true)
        }
      })
      .catch((error) => toast.error("Error fetching data:" + error));

    textareaRef?.current?.focus();
  };
  const reindexTree = (nodes, parents = [], path = "") => {
    return nodes.map((node, index) => {
      const currentId =
        parents && parents.length
          ? `${parents[parents.length - 1]}.${index + 1}`
          : `${index + 1}`;
      const newNode = {
        ...node,
        id: currentId,
        parents: parents,
        path: getLabelTitle(path, node.title),
      };
      const childParent = [...parents, currentId];
  
      if (node.children && node.children.length > 0) {
        newNode.children = reindexTree(node.children, childParent, newNode.path);
      }
      return newNode;
    });
  };

  const getLabelTitle = (parent, title) =>
  parent && parent.length > 0 ? `${parent} > ${title}` : title;
  
  const treeItems = reindexTree(trees);

  useEffect(() => {
    if (!hierarchy) {
      const parents = ["1", "2", "3", "15"];
      setSelectedIds([...parents]);
      const paths = parents.map((parent) => getNodeById(treeItems, parent).path);
      console.log(paths)
      setSelectedPaths([...paths]);
    }
    
  }, [hierarchy]);

  useEffect(()=>{
    setDataSource("azureS")
    setModel("gpt-4o")
    setHierarchy(false)
    setLegalInfo("false")
    setFastSearch(null)
    setToolTip('false')
    setDefaultSearch(true)
  },[])

  const getNodeById = (nodes, id) => {
    for (const node of nodes) {
      if (node.id === id) {
        return node;
      } else if (node.children && node.children.length) {
        const foundChild = getNodeById(node.children, id);
        if (foundChild) {
          return foundChild;
        }
      }
    }
    return null;
  };

  return (
    <div className="chat-container">

      {sidebar()}

      <div className="main-container">
        <ChatContent
          isTermsAgree={isTermsAgree}
          setIsTermsAgree={setIsTermsAgree}
          isThinking={isThinking}
          containerRef={containerRef}
          chatContents={chatContents}
          setChatText={setChatText}
          setSelectedText = {setSelectedText}
        />
        {!searchParams.get('chatId') && (
          <>
            {promptLink && (
              <div className="prompt-link">
                <span to={promptLink.url} onClick={() => window.location.href=promptLink.url}>
                  You can explore {promptLink.text} in an external link by clicking here.
                </span>
              </div>
            )}
            <ChatInput
              chatId = {selectedChat}
              link= {link}
              setLinkCopied={setLinkCopied}
              fetchCode ={fetchCode}
              isTermsAgree={isTermsAgree}
              textareaRef={textareaRef}
              isThinking={isThinking}
              canEdit={canEdit}
              handleChatInputKeyDown={handleChatInputKeyDown}
              handleChatTextChange={handleChatTextChange}
              sendClicked={sendClicked}
              learnClicked={learnClicked}
              isSendBtnActive={isSendBtnActive}
              isLearnActive={isLearnActive}
              canChat = {canChat}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ChatPage;
