import Drawer from "react-modern-drawer";
import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import HierrachyTree from "./HierrachyTree";
import { useEffect } from "react";

const DataSource = ({ open, onClose, dataSource, setDataSource, selectedIds, setSelectedIds, setSelectedPaths, hierarchy }) => {
  useEffect(()=>{
    console.log(selectedIds, "")
  },[])
  const handleChange = (event) => {
    setDataSource(event.target.value);
  };

  return (
    <Drawer open={open} onClose={onClose} direction="right" size="365px" style={{ border: "1px solid #2895EB", top: "100px", height: "auto" }} >
      <Box className="drawer-title">ATO Database</Box>
      <HierrachyTree selectedIds={selectedIds} setSelectedIds={setSelectedIds} setSelectedPaths={setSelectedPaths} hierarchy={hierarchy}/>
    </Drawer>
  );
};

export default DataSource;
