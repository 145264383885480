import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Box } from "@mui/material";

import Header from "../components/header/Header";
import BrowsePage from "./browse/BrowsePage";
import ChatPage from "./chat/ChatPage";
import "../styles/layout/Home.css";
import DataSource from "./drawer/DataSource";
import SearchOption from "./drawer/SearchOption";
import Tooltip from '@mui/material/Tooltip';
import SpecificSearch from "./drawer/SpecificSearch";
import { useSearchParams } from "react-router-dom";
import LegalInfo from "./drawer/LegalInfo";
import ScratchPadPage from "./scratch-pad/ScratchPadPage";
import AdvancedSearch from "./drawer/AdvancedSearch";
import HelpGuides from "./drawer/HelpGuides";
import ResearchOptions from "./drawer/ResearchOptions";
import ReverseSearch from "./reverse-search/ReverseSearch";
import { Radio, RadioGroup, FormControlLabel, Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const Home = () => {
  const tabName = localStorage.getItem("tabName");

  const [container, setContainer] = useState(tabName ? tabName : "Chat");
  const [isConfigOpen, setConfigOpen] = useState(false);
  const [dataSource, setDataSource] = useState("azureS");
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedPaths, setSelectedPaths] = useState([]);
  const [model, setModel] = useState("gpt-4o");
  const [hierarchy, setHierarchy] = useState(false);
  const [legalInfo, setLegalInfo] = useState("false");
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedText, setSelectedText] = useState('');
  const [scratchText, setScratchText] = useState('');
  const [defaultSearch, setDefaultSearch] = useState(true);
  const [defaultSearchOpen, setDefaultSearchOpen] = useState(false);
  const [fastSearch, setFastSearch] = useState(null);
  const [toolTip, setToolTip] = useState('false');
  const [helpGuides, setHelpGuides] = useState('true');
  const [helpGuidesOpen, setHelpGuidesOpen] = useState(false);
  const [researchOptions, setResearchOptions] = useState(false);
  const [selectedSource, setSelectedSource] = useState("ATO");

  let tempSelectedText = '';

  useEffect(() => {
    localStorage.setItem("tabName", container);
  }, [container]);

  useEffect(() => {
    const fastSearchParam = searchParams.get("fastSearch");

    if (fastSearchParam) {
      const decodedFastSearch = decodeURIComponent(fastSearchParam);
      setDataSource(null);
      setDefaultSearch(false);
      setFastSearch(decodedFastSearch);
    }
  }, []);

  useEffect(() => {
    if (selectedText) {
      tempSelectedText = selectedText;
    }
    console.log("Selected Text", selectedText);
  }, [selectedText]);

  const addToScratch = () => {
    console.log(tempSelectedText, "Selected Text");
    tempSelectedText += "\n";
    console.log(tempSelectedText, "Hello");
    setScratchText((prev) => {
      return prev + '\n' + tempSelectedText;
    });
    setSelectedText('');
  };

  const handleChange = (event) => {
    setDefaultSearch(event);
    if (event === true) {
      setDataSource("azureS");
      setModel("gpt-4o");
      setHierarchy(false);
      setLegalInfo("false");
      setFastSearch(null);
      setToolTip('false');
    }
  };

  const handleRadioChange = (event) => {
    setFastSearch(null);
    setDataSource(event.target.value);
    if (defaultSearch) handleChange(false);
  };

  return (
    <>
      <div className="container">
        <Header />
        <Box className="container-box">
          {container === "Chat" && (
            <ChatPage
              container={container}
              setContainer={setContainer}
              dataSource={dataSource}
              selectedPaths={selectedPaths}
              model={model}
              hierarchy={hierarchy}
              legalInfo={legalInfo}
              setSelectedText={setSelectedText}
              fastSearch={fastSearch}
              toolTip={toolTip}
              setSelectedIds={setSelectedIds}
              setSelectedPaths={setSelectedPaths}
              setHierarchy={setHierarchy}
              setDataSource={setDataSource}
              setFastSearch={setFastSearch}
              setModel={setModel}
              setDefaultSearch={setDefaultSearch}
              setLegalInfo={setLegalInfo}
              setToolTip={setToolTip}
              helpGuides={helpGuides}
            />
          )}
          {container === "Browse" && (
            <BrowsePage
              container={container}
              setContainer={setContainer}
              dataSource={dataSource}
              selectedPaths={selectedPaths}
              setSelectedIds={setSelectedIds}
              setSelectedPaths={setSelectedPaths}
              hierarchy={hierarchy}
              setHierarchy={setHierarchy}
              setDataSource={setDataSource}
              setFastSearch={setFastSearch}
              setModel={setModel}
              setDefaultSearch={setDefaultSearch}
              setLegalInfo={setLegalInfo}
              setToolTip={setToolTip}
            />
          )}
          {container === "ScratchPad" && (
            <ScratchPadPage
              container={container}
              setContainer={setContainer}
              dataSource={dataSource}
              selectedPaths={selectedPaths}
              model={model}
              hierarchy={hierarchy}
              legalInfo={legalInfo}
              selectedText={scratchText}
            />
          )}
          {container === "ReverseSearch" && (
            <ReverseSearch
              container={container}
              setContainer={setContainer}
              selectedPaths={selectedPaths}
              selectedText={scratchText}
              setSelectedText={setSelectedText}
            />
          )}
        </Box>

        {searchParams.get('fastSearch') && (
          <Box></Box>
        )}

        <HelpGuides
          helpGuides={helpGuides}
          setHelpGuides={setHelpGuides}
          helpGuidesOpen={helpGuidesOpen}
          setHelpGuidesOpen={() => { setHelpGuidesOpen(false); }}
        />

        {!searchParams.get('chatId') && !searchParams.get('fastSearch') && container !== 'ScratchPad' && container !== 'Browse' && (
          <Box>
            <Tooltip
              title={dataSource === 'google' && container !== 'Browse' ? 'Data sources not available during google and fast search' : ''}
              placement="top"
            >
              <div className="search-options-header" >
                <div className="search-options-container">
                  <div>
                    <h4
                      className="btn-data-source-label"
                      style={{ display: (dataSource === 'google' || dataSource === 'google_com' ? 'none' : 'block') }}
                    >
                      Please choose correct data sources for accurate results
                    </h4>
                    <button
                      onClick={() => setConfigOpen(!isConfigOpen)}
                      disabled={(dataSource === 'google' || dataSource === 'google_com' || hierarchy || fastSearch != null) && container !== 'Browse'}
                      className="btn-data-source"
                      style={{ display: (dataSource === 'google' || dataSource === 'google_com' ? 'none' : 'block') }}
                    >
                      DATA SOURCES
                    </button>
                  </div>

                  <h4 className="search-option-radios-label">Search Options</h4>
                  <RadioGroup
                    className="search-option-radios"
                    sx={{ py: 1, pl: 2, display: "flex", flexDirection: "row" }}
                    value={dataSource}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="azureS"
                      control={<Radio />}
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          ATO Databases&nbsp;
                          <Tooltip title="ATO Databases provide structured and accurate data from official sources, ensuring reliable search results.">
                            <InfoIcon sx={{ mr: 1 }} style={{color:"#2895eb"}} />
                          </Tooltip>
                        </Box>
                      }
                    />

                    <FormControlLabel
                      value="google"
                      control={<Radio />}
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          Google Search&nbsp;
                          <Tooltip title="Google Search API allows you to retrieve real-time and comprehensive search results, targeting specific sources like the ATO website.">
                            <InfoIcon sx={{ mr: 1 }} style={{color:"#2895eb"}} />
                          </Tooltip>
                        </Box>
                      }
                    />

                    <FormControlLabel
                      value="google_com"
                      control={<Radio />}
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          Google Commentaries&nbsp;
                          <Tooltip title="Google Commentaries pull data from broader sources beyond ATO, offering a more comprehensive search experience.">
                            <InfoIcon sx={{ mr: 1 }} style={{color:"#2895eb"}} />
                          </Tooltip>
                        </Box>
                      }
                    />
                  </RadioGroup>
                </div>

                <div className="action-buttons" style={{ marginTop: "15px", display: "flex", gap: "10px" }}>
                  {container === "Chat" && (
                    <button className="btn-search-options" onClick={() => {
                      setDefaultSearchOpen(!defaultSearchOpen);
                    }}>
                      ADVANCED SEARCH
                    </button>
                  )}

                  {container === 'Chat' && (
                    <button className="btn-model-options" onClick={() => {
                      setResearchOptions(true);
                    }}>
                      RESEARCH OPTIONS
                    </button>
                  )}

                  {((container === 'Chat' || container === 'ReverseSearch') && selectedText) && (
                    <button className="btn-btn-scratch-pad" onClick={addToScratch}>
                      ADD TO SCRATCH PAD
                    </button>
                  )}
                </div>
              </div>
            </Tooltip>

            <DataSource
              open={isConfigOpen}
              onClose={() => setConfigOpen(false)}
              dataSource={dataSource}
              setDataSource={setDataSource}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              setSelectedPaths={setSelectedPaths}
              hierarchy={hierarchy}
            />

            {container === "Chat" && (
              <AdvancedSearch
                open={defaultSearchOpen}
                dataSource={dataSource}
                setDataSource={setDataSource}
                model={model}
                setModel={setModel}
                defaultSearch={defaultSearch}
                setDefaultSearch={setDefaultSearch}
                hierarchy={hierarchy}
                setHierarchy={setHierarchy}
                legalInfo={legalInfo}
                setLegalInfo={setLegalInfo}
                fastSearch={fastSearch}
                setFastSearch={setFastSearch}
                setSelectedIds={setSelectedIds}
                setSelectedPaths={setSelectedPaths}
                toolTip={toolTip}
                setToolTip={setToolTip}
                onClose={() => {
                  setDefaultSearchOpen(false);
                }}
              />
            )}

            {container === 'Chat' && (
              <ResearchOptions
                open={researchOptions}
                onClose={() => { setResearchOptions(false); }}
              />
            )}
          </Box>
        )}

        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    </>
  );
};

export default Home;
