// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-container {
  display: flex;
  width: 100%;
  background-color: #fcfcf9;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background: white;
}

.drawer-title {
  background: #2895eb;
  color: white;
  font-size: 11px;
  font-weight: 600;
  text-align: left;
  padding: 7px 25px 8px 25px;
  font-weight: 400;
}
.custom-drawer-wrapper{
  width: 50% !important;
  overflow: scroll;
  height: 514px !important;

}


.custom-drawer-wrapper::-webkit-scrollbar{
  -webkit-appearance: none;
  width: 7px;
}

.custom-drawer-wrapper::-webkit-scrollbar-thumb{
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
}






 .custom-drawer-wrapper .css-vqmohf-MuiButtonBase-root-MuiRadio-root{ padding: 7px !important;}

 






.custom-drawer{
  width: 100%;
  display:flex;
}
.custom-drawer .left-side{
  width: 50%;
}
.custom-drawer .right-side{
  width: 50%;
}

.prompt-link {
  padding: 10px;
  margin: 0 20px;
  background-color: #f0f0f0;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

.prompt-link a {
  color: #0066cc;
  text-decoration: none;
}

.prompt-link a:hover {
  text-decoration: underline;
}



`, "",{"version":3,"sources":["webpack://./src/styles/layout/chat/ChatPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAO;EACP,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,0BAA0B;EAC1B,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,wBAAwB;;AAE1B;;;AAGA;EACE,wBAAwB;EACxB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,mCAAmC;AACrC;;;;;;;CAOC,qEAAqE,uBAAuB,CAAC;;;;;;;;;AAS9F;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,cAAc;EACd,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".chat-container {\n  display: flex;\n  width: 100%;\n  background-color: #fcfcf9;\n}\n\n.main-container {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  background: white;\n}\n\n.drawer-title {\n  background: #2895eb;\n  color: white;\n  font-size: 11px;\n  font-weight: 600;\n  text-align: left;\n  padding: 7px 25px 8px 25px;\n  font-weight: 400;\n}\n.custom-drawer-wrapper{\n  width: 50% !important;\n  overflow: scroll;\n  height: 514px !important;\n\n}\n\n\n.custom-drawer-wrapper::-webkit-scrollbar{\n  -webkit-appearance: none;\n  width: 7px;\n}\n\n.custom-drawer-wrapper::-webkit-scrollbar-thumb{\n  border-radius: 4px;\n  background-color: rgba(0, 0, 0, .5);\n}\n\n\n\n\n\n\n .custom-drawer-wrapper .css-vqmohf-MuiButtonBase-root-MuiRadio-root{ padding: 7px !important;}\n\n \n\n\n\n\n\n\n.custom-drawer{\n  width: 100%;\n  display:flex;\n}\n.custom-drawer .left-side{\n  width: 50%;\n}\n.custom-drawer .right-side{\n  width: 50%;\n}\n\n.prompt-link {\n  padding: 10px;\n  margin: 0 20px;\n  background-color: #f0f0f0;\n  border-radius: 4px;\n  text-align: center;\n  cursor: pointer;\n}\n\n.prompt-link a {\n  color: #0066cc;\n  text-decoration: none;\n}\n\n.prompt-link a:hover {\n  text-decoration: underline;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
