import Drawer from "react-modern-drawer";
import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import HierrachyTree from "./HierrachyTree";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { useEffect } from "react";

const ToolTip = ({ toolTip, setToolTip, defaultSearch, handleDefaultSearch, dataSource, setFastSearch }) => {


    const handleModelChange = (event) => {

        setFastSearch(null)
        setToolTip(event.target.value);
        if (defaultSearch) {
            console.log("hello")
            handleDefaultSearch(false)
        }
        console.log(event.target.value)
    };

    useEffect(() => {
        if (dataSource === 'google')
            setToolTip('false')


    }, [dataSource])

    return (


        <>
            <Box className="drawer-title">Tooltip</Box>

            {/* <h3 style={{ marginLeft: '15px', marginBottom: '0px' }}>Models</h3> */}
            <RadioGroup
                sx={{
                    py: 1,
                    pl: 2,
                    display: 'flex',
                    flexDirection: 'row', // To align items horizontally
                    justifyContent: 'flex-start', // To align items at the start
                }}
                value={toolTip}
                onChange={handleModelChange}
            >
                <FormControlLabel value="true" control={<Radio />} label="Enabled" sx={{ mr: 1 }} disabled={dataSource != 'google' ? false : true} />
                <FormControlLabel value="false" control={<Radio />} label={<Box sx={{ display: 'flex', alignItems: 'center' }} >
                    Disabled &nbsp; <Tooltip title="If enabled you will see refrences as tooltips, like the IEEE Format"><InfoIcon sx={{ mr: 1 }} /></Tooltip></Box>} sx={{ mr: 1 }} disabled={dataSource != 'google' ? false : true}/>
            </RadioGroup>
        </>

    );
};

export default ToolTip;
