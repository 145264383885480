import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import { API_BASE_URL } from "../../utils/const";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Button,
  CircularProgress,
  Box,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Table,
  TablePagination,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { toast } from "react-toastify";

const AOAOptions = [
  {
    label: "Advance",
    value: "0",
  },
  {
    label: "Arrears",
    value: "1",
  },
];

const PeriodOptions = [
  {
    label: "Daily",
    value: "365",
  },
  {
    label: "Weekly",
    value: "52",
  },
  {
    label: "Fortnight",
    value: "26",
  },
  {
    label: "Monthly",
    value: "12",
  },
];

const applyPagination = (results, page, limit) =>
  results.slice(page * limit, page * limit + limit);

const HPAnalyzer = () => {
  const [borrowedAmount, setBorrowedAmount] = useState("");
  const [numberofPayments, setNumberofPayments] = useState("");
  const [repaymentAmount, setRepaymentAmount] = useState("");
  const [firstPay, setFirstPay] = useState(AOAOptions[0].value);
  const [period, setPeriod] = useState(PeriodOptions[0].value);
  const [startDate, setStartDate] = useState("2015-01-01");
  const [balloons, setBalloons] = useState([
    { index: 0, period: "", amount: "" },
  ]);
  const [isShowResults, setIsShowResults] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handleFirstPay = (event) => {
    setFirstPay(event.target.value);
  };
  const handleBorrowedAmount = (event) => {
    setBorrowedAmount(event.target.value);
  };
  const handleNumberofPayments = (event) => {
    setNumberofPayments(event.target.value);
  };
  const handleRepaymentAmount = (event) => {
    setRepaymentAmount(event.target.value);
  };
  const handlePeriod = (event) => {
    setPeriod(event.target.value);
  };
  const handleStartDate = (newDate) => {
    setStartDate(newDate);
  };

  const handleCalculate = async () => {
    if (
      borrowedAmount === "" ||
      numberofPayments === "" ||
      repaymentAmount === ""
    ) {
      toast.warn("Please input the values correctly.");
      return;
    }

    for (const balloon of balloons) {
      if (balloon.amount === "" || balloon.period === "") {
        toast.warn("Please input the values correctly.");
        return;
      }
    }

    setIsCalculating(true);

    const postData = {
      borrowed_amount: borrowedAmount,
      number_of_payments: numberofPayments,
      repayment_amount: repaymentAmount,
      first_payment: firstPay,
      period: period,
      date: moment(startDate).format("YYYY-MM-DD"),
      balloons: balloons,
    };

    axios
      .post(`${API_BASE_URL}/chat/get-hp-analyzer`, postData)
      .then((response) => {
        if (response.data) {
          const retVal = JSON.parse(response.data);
          setResultData(retVal);
          setIsShowResults(true);
        }
        setIsCalculating(false);
      })
      .catch((error) => {
        toast.error("Error fetching data:" + error);
        setIsCalculating(false);
      });
  };

  const getIndexSuffix = (i) => {
    switch (i) {
      case 0:
        return "st";
      case 1:
        return "nd";
      case 2:
        return "rd";
      default:
        return "th";
    }
  };

  const paginatedResults = applyPagination(resultData, page, limit);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        mt: 2,
        maxWidth: "800px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ width: "300px" }}
        >
          Borrowing Amount
        </Typography>
        <TextField
          size="small"
          type="number"
          variant="outlined"
          onChange={handleBorrowedAmount}
          value={borrowedAmount}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ width: "300px" }}
        >
          Number of Payments
        </Typography>
        <TextField
          size="small"
          type="number"
          variant="outlined"
          onChange={handleNumberofPayments}
          value={numberofPayments}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ width: "300px" }}
        >
          Repayment Amount
        </Typography>
        <TextField
          size="small"
          type="number"
          variant="outlined"
          onChange={handleRepaymentAmount}
          value={repaymentAmount}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ width: "300px" }}
        >
          Advance or Arrears
        </Typography>
        <FormControl size="small">
          <Select
            value={firstPay}
            onChange={handleFirstPay}
            sx={{ width: "210px" }}
          >
            {AOAOptions.map((aoaOpt) => (
              <MenuItem value={aoaOpt.value} key={aoaOpt.value}>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  sx={{ lineHeight: 1.5 }}
                >
                  {aoaOpt.label}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ width: "300px" }}
        >
          Period
        </Typography>
        <FormControl size="small">
          <Select
            value={period}
            onChange={handlePeriod}
            sx={{ width: "210px" }}
          >
            {PeriodOptions.map((periodOpt) => (
              <MenuItem value={periodOpt.value} key={periodOpt.value}>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  sx={{ lineHeight: 1.5 }}
                >
                  {periodOpt.label}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ width: "300px" }}
        >
          Date
        </Typography>
        <DatePicker
          value={moment(startDate)}
          onChange={handleStartDate}
          sx={{
            width: "210px",
            "& .MuiOutlinedInput-input": { padding: "8.5px 14px" },
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
      {balloons.map((item, index) => (
        <Box sx={{ display: "flex", alignItems: "center" }} key={index}>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ width: "300px" }}
          >
            {item.index + 1}
            {getIndexSuffix(item.index)}
            &nbsp;Balloon if applicable
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            <TextField
              type="number"
              size="small"
              value={balloons[item.index].period}
              variant="outlined"
              onChange={(e) => {
                const tmp = balloons.slice(0);
                tmp[item.index].period = e.target.value;
                if (
                  parseFloat(tmp[tmp.length - 1].period) > 0 &&
                  parseFloat(tmp[tmp.length - 1].amount)
                ) {
                  tmp.push({ index: tmp.length, amount: "0", period: "0" });
                }
                setBalloons(tmp);
              }}
              placeholder="Period"
            />
            <TextField
              type="number"
              size="small"
              value={balloons[item.index].amount}
              variant="outlined"
              onChange={(e) => {
                const tmp = balloons.slice(0);
                tmp[item.index].amount = e.target.value;
                if (
                  parseFloat(tmp[tmp.length - 1].period) > 0 &&
                  parseFloat(tmp[tmp.length - 1].amount)
                ) {
                  tmp.push({ index: tmp.length, amount: "0", period: "0" });
                }
                setBalloons(tmp);
              }}
              placeholder="Amount"
            />
          </Box>
        </Box>
      ))}
      {!isShowResults && (
        <Box sx={{ mt: 2 }}>
          <Button
            className="btn-tax-calculate"
            variant="contained"
            onClick={handleCalculate}
            disabled={isCalculating}
          >
            {isCalculating && <CircularProgress size={20} />}
            Calculate
          </Button>
        </Box>
      )}
      {isShowResults && (
        <Box sx={{ mt: 2 }}>
          <Table
            style={{
              width: "100%",
              marginTop: 16,
            }}
            sx={{
              "& th": {
                py: 1.5,
                px: 1,
                fontSize: "16px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                bgcolor: "#2895eb",
                color: "white",
                fontWeight: "medium",
              },
            }}
          >
            <TableHead
              style={{
                width: "100%",
              }}
            >
              <TableRow
                sx={{
                  width: "100%",
                }}
              >
                <TableCell
                  sx={{
                    width: "10%",
                    px: 2,
                  }}
                >
                  Instalment
                </TableCell>
                <TableCell
                  sx={{
                    width: "15%",
                    px: 2,
                  }}
                >
                  Month
                </TableCell>
                <TableCell
                  sx={{
                    width: "15%",
                    px: 2,
                  }}
                >
                  Opening Balance
                </TableCell>
                <TableCell
                  sx={{
                    width: "15%",
                    px: 2,
                  }}
                >
                  Rate
                </TableCell>
                <TableCell
                  sx={{
                    width: "15%",
                    px: 2,
                  }}
                >
                  Interest
                </TableCell>
                <TableCell
                  sx={{
                    width: "15%",
                    px: 2,
                  }}
                >
                  Repayment
                </TableCell>
                <TableCell
                  sx={{
                    width: "15%",
                    px: 2,
                  }}
                >
                  Closing Balance
                </TableCell>
              </TableRow>
            </TableHead>
            {paginatedResults?.length !== 0 && (
              <TableBody
                style={{
                  width: "100%",
                }}
              >
                {paginatedResults.map((row) => (
                  <TableRow
                    hover
                    key={row[0]}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <TableCell
                      sx={{
                        width: "10%",
                        px: 2,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          color: "text.secondary",
                        }}
                      >
                        {row[0]}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "15%",
                        px: 2,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          color: "text.secondary",
                        }}
                      >
                        {row[1]}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "15%",
                        px: 2,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          color: "text.secondary",
                        }}
                      >
                        {(row[2] * 1).toFixed(3)}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "15%",
                        px: 2,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          color: "text.secondary",
                        }}
                      >
                        {(row[3] * 1).toFixed(3)}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "15%",
                        px: 2,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          color: "text.secondary",
                        }}
                      >
                        {(row[4] * 1).toFixed(3)}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "15%",
                        px: 2,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          color: "text.secondary",
                        }}
                      >
                        {(row[5] * 1).toFixed(3)}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "15%",
                        px: 2,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          color: "text.secondary",
                        }}
                      >
                        {(row[6] * 1).toFixed(3)}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "flex-start",
              px: 10,
            }}
          >
            <TablePagination
              component="div"
              count={resultData.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default HPAnalyzer;
