import "../../../styles/components/browse/Sidebar.css";
import "../../../styles/components/browse/History.css";
import { Button } from "@mui/material";
import SidebarSkeleton from "../../skeleton/SidebarSkeleton";
import { Add } from "@mui/icons-material";

const Sidebar = ({ container, setContainer, newChatClicked,chatLists }) => {

    // Group chats by date
  const groupedChats = chatLists.reduce((groups, chat) => {
    if (!groups[chat.datetime]) {
      groups[chat.datetime] = [];
    }
    groups[chat.datetime].push(chat);
    return groups;
  }, {});
    return (
        <div className="browse-sidebar">
            <div className="switch-container">
                <div className="btn-group">
                    <Button className={container === "Chat" ? "btn-chat active" : "btn-chat"} onClick={() => setContainer("Chat")}>
                        Chat
                    </Button>
                    <Button className={container === "Browse" ? "btn-browse active" : "btn-browse"} onClick={() => setContainer("Browse")}>
                        Search
                    </Button>
                    <Button className={container === "ScratchPad" ? "btn-scratch active" : "btn-scratch"} onClick={() => setContainer("ScratchPad")}>
                        Scratch
                    </Button>

                    <Button className={container === "ReverseSearch" ? "btn-reverse active" : "btn-reverse"} onClick={() => setContainer("ReverseSearch")}>
                        Reverse Search
                    </Button>
                </div>
            </div>
            {/* <div className="browse-history-container">
                {isLoading ? <SidebarSkeleton /> : histories}
            </div> */}
            <div className="new-chat-container">
                <Button className="btn-new-chat" style={{ marginTop: '10px' }} onClick={newChatClicked}>
                    <Add />
                    <span >Start New Chat</span>
                </Button>
                <div className="flex flex-col w-full bg-white" style={{marginTop:'10px'}}>
      {Object.entries(groupedChats).map(([date, dateChats]) => (
        <div key={date} className="flex flex-col">
          <div className="py-2 px-4 text-sm text-gray-600 bg-gray-50 chat-history-date">
            {date}
          </div>
          <div className="flex flex-col">
            {dateChats.map((chat) => (
              <div
                key={chat.id}
                className="px-4 py-2 hover:bg-gray-50 cursor-pointer text-sm text-gray-700 border-b border-gray-100 last:border-b-0"
              >
                <div className="truncate chat-history-body-link" style={{fontSize:'12px'}}>
                  {chat.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div> 
            </div>
        </div>
    );
}

export default Sidebar