import { Skeleton } from "@mui/material";

const SidebarHistory = () => {
  return (
    <>
      <Skeleton variant="text" animation="wave" sx={{ fontSize: "12px", mb: "5px" }} width={100} />
      <Skeleton variant="text" animation="wave" sx={{ fontSize: "12px" }} />
      <Skeleton variant="text" animation="wave" sx={{ fontSize: "12px" }} />
      <Skeleton variant="text" animation="wave" sx={{ fontSize: "12px" }} width={200} />
      <Skeleton variant="text" animation="wave" sx={{ fontSize: "12px" }} width={180} />
      <Skeleton variant="text" animation="wave" sx={{ fontSize: "12px" }} />
      <Skeleton variant="text" animation="wave" sx={{ fontSize: "12px", mb: "20px" }} width={240} />
    </>
  );
};

const SidebarSkeleton = () => {
  return (
    <>
      <SidebarHistory />
      <SidebarHistory />
      <SidebarHistory />
      <SidebarHistory />
      <SidebarHistory />
      <SidebarHistory />
    </>
  );
};

export default SidebarSkeleton;
