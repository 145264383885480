import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import Chart from "react-apexcharts";
import { useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { API_BASE_URL } from "../../utils/const";

const GenderOptions = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

const FindOptions = [
  {
    label: "No of years to zero",
    value: "0",
  },
  {
    label: "Pension for annum",
    value: "1",
  },
];

const BooleanOptions = [
  {
    case: false,
    value: "False",
  },
  {
    case: true,
    value: "True",
  },
];

const initialData = {
  gender: GenderOptions[0].value,
  dateOfBirth: "1970-06-30",
  actuarialLife: BooleanOptions[0].value,
  lifeExpectancy: "85",
  retirementAge: "65",
  currentBalanceTaxed: "122000",
  currentBalanceUntaxed: "0",
  currentBalanceTaxfree: "0",
  currentIncome: "132000",
  regularIncomeInc: "3",
  employerContribution: "9.5",
  inflation: "3",
  lumpSumIncomeChange: "0",
  lumpSumYearStart: "0",
  lumpSumTaxableRollover: "0",
  lumpSumTaxableRolloverStart: "0",
  maxContribution: "25000",
  salarySacrificeType: BooleanOptions[0].value,
  salarySacrifice: "0",
  personalDeductableType: BooleanOptions[0].value,
  personalContribution: "0",
  personalNonDeductableType: BooleanOptions[0].value,
  personalTaxFreeContribution: "0",
  retirementLumpSumType: BooleanOptions[1].value,
  lumpSum: "0",
  superannuationTaxRate: "15",
  superFeeType: BooleanOptions[0].value,
  superFees: "1500",
  superGrowth: "5",
  find: FindOptions[0].value,
  annuity: "22000",
};

const MaxMinRange = {
  lifeExpectancy: { minValue: 21, maxValue: 100 },
  retirementAge: { minValue: 55, maxValue: 70 },
  currentBalanceTaxed: { minValue: 0, maxValue: 2000000 },
  currentBalanceUntaxed: { minValue: 0, maxValue: 2000000 },
  currentBalanceTaxfree: { minValue: 0, maxValue: 2000000 },
  currentIncome: { minValue: 0, maxValue: 2000000 },
  regularIncomeInc: { minValue: 0, maxValue: 100 },
  employerContribution: { minValue: 0, maxValue: 100 },
  inflation: { minValue: 0, maxValue: 20 },
  lumpSumIncomeChange: { minValue: 0, maxValue: 10000 },
  lumpSumYearStart: { minValue: 0, maxValue: 100 },
  lumpSumTaxableRollover: { minValue: 0, maxValue: 10000 },
  lumpSumTaxableRolloverStart: { minValue: 0, maxValue: 100 },
  maxContribution: { minValue: 0, maxValue: 35000 },
  salarySacrifice: { minValue: 0, maxValue: 2000000 },
  personalContribution: { minValue: 0, maxValue: 2000000 },
  personalTaxFreeContribution: { minValue: 0, maxValue: 2000000 },
  lumpSum: { minValue: 0, maxValue: 10000 },
  superannuationTaxRate: { minValue: 0, maxValue: 100 },
  superFees: { minValue: 0, maxValue: 20000 },
  superGrowth: { minValue: 0, maxValue: 100 },
  annuity: { minValue: 0, maxValue: 200000 },
};

const Superannuation = () => {
  const theme = useTheme();
  const [isCalculating, setIsCalculating] = useState(false);
  const [isCheckingLife, setIsCheckingLife] = useState(false);
  const [superannuationData, setSuperannuationData] = useState(initialData);
  const [isShowResults, setIsShowResults] = useState(false);
  const [resultVal, setResultVal] = useState(null);
  const [xAxisData, setXAxisData] = useState([]);
  const [yAxisData1, setYAxisData1] = useState([]);
  const [yAxisData2, setYAxisData2] = useState([]);
  const [yAxisData3, setYAxisData3] = useState([]);

  const data = {
    series: [
      {
        color: "#4CAF50",
        name: "Real Superannuation",
        data: yAxisData1,
      },
      {
        color: "#FF9800",
        name: "Nominal Pre-retirement",
        data: yAxisData2,
      },
      {
        color: "#F44336",
        name: "Nominal Post-retirement",
        data: yAxisData3,
      },
    ],
    xAxis: {
      dataPoints: xAxisData,
    },
  };

  const chartOptions = {
    chart: {
      background: "transparent",
      stacked: false,
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: data.series.map((item) => item.color),
    dataLabels: {
      enabled: false,
    },
    grid: {
      borderColor: theme.palette.divider,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      horizontalAlign: "right",
      labels: {
        colors: theme.palette.text.secondary,
      },
      position: "top",
      show: true,
    },
    stroke: {
      curve: "stepline",
      lineCap: "butt",
      width: 3,
    },
    theme: {
      mode: theme.palette.mode,
    },
    title: {
      text: "Balance Graphs",
      style: {
        fontSize: "16px",
        color: theme.palette.text.secondary,
      },
    },
    tooltip: {
      enabled: true,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      categories: data.xAxis.dataPoints,
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
      title: {
        text: "Age",
        style: {
          fontWeight: "regular",
          fontSize: "13px",
          color: theme.palette.text.secondary,
        },
      },
    },
    yaxis: [
      {
        axisBorder: {
          color: theme.palette.divider,
          show: true,
        },
        axisTicks: {
          color: theme.palette.divider,
          show: true,
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
        decimalsInFloat: 0,
        title: {
          text: "Superannuation Balance",
          style: {
            fontWeight: "regular",
            fontSize: "13px",
            color: theme.palette.text.secondary,
          },
        },
      },
    ],
  };

  const dataErrorText = (percentKey, dataKey) => {
    const value = superannuationData[dataKey];
    if (value === "") return "";
    const { minValue, maxValue } =
      percentKey !== "" && superannuationData[percentKey] === "True"
        ? { minValue: 0, maxValue: 100 }
        : MaxMinRange[dataKey];
    if (minValue <= Number(value) && Number(value) <= maxValue) return "";
    return `Value should be between ${minValue} and ${maxValue}`;
  };

  const isdataError = (percentKey, dataKey) => {
    const value = superannuationData[dataKey];
    if (value === "") return true;
    const { minValue, maxValue } =
      percentKey !== "" && superannuationData[percentKey] === "True"
        ? { minValue: 0, maxValue: 100 }
        : MaxMinRange[dataKey];
    return !(minValue <= Number(value) && Number(value) <= maxValue);
  };

  const someDataError =
    (!BooleanOptions.filter(
      (booleanOpt) => booleanOpt.value === superannuationData.actuarialLife
    )[0].case &&
      isdataError("", "lifeExpectancy")) ||
    isdataError("", "retirementAge") ||
    isdataError("", "currentBalanceTaxed") ||
    isdataError("", "currentBalanceTaxed") ||
    isdataError("", "currentBalanceUntaxed") ||
    isdataError("", "currentBalanceTaxfree") ||
    isdataError("", "currentIncome") ||
    isdataError("", "regularIncomeInc") ||
    isdataError("", "employerContribution") ||
    isdataError("", "inflation") ||
    isdataError("", "lumpSumIncomeChange") ||
    isdataError("", "lumpSumYearStart") ||
    isdataError("", "lumpSumTaxableRollover") ||
    isdataError("", "lumpSumTaxableRolloverStart") ||
    isdataError("", "maxContribution") ||
    isdataError("salarySacrificeType", "salarySacrifice") ||
    isdataError("personalDeductableType", "personalContribution") ||
    isdataError("personalNonDeductableType", "personalTaxFreeContribution") ||
    isdataError("retirementLumpSumType", "lumpSum") ||
    isdataError("", "superannuationTaxRate") ||
    isdataError("superFeeType", "superFees") ||
    isdataError("", "superGrowth") ||
    isdataError("", "annuity");

  const setDataValue = (percentKey, dataKey, dataValue) => {
    setSuperannuationData({ ...superannuationData, [dataKey]: dataValue });
  };

  const setPercentMode = (valueKey, dataKey) => {
    const checked = superannuationData[dataKey] === "True";
    const val = checked
      ? Math.min(
          Math.max(
            parseFloat(superannuationData[valueKey]),
            MaxMinRange[valueKey].minValue
          ),
          MaxMinRange[valueKey].maxValue
        )
      : Math.min(Math.max(parseFloat(superannuationData[valueKey]), 0), 100);
    setSuperannuationData({
      ...superannuationData,
      [dataKey]: checked ? "False" : "True",
      [valueKey]: superannuationData[valueKey] !== "" ? val : "",
    });
  };

  const setLifeExpectancy = () => {
    const checked = superannuationData.actuarialLife === "True";
    const postData = {
      gender: superannuationData.gender,
      age:
        new Date().getFullYear() -
        parseInt(superannuationData.dateOfBirth.split("-")[0], 10),
    };
    if (!checked) {
      setIsCheckingLife(true);
      axios
        .post(`${API_BASE_URL}/chat/get-life-expectancy`, postData)
        .then((res) => {
          const retVal = JSON.parse(res.data);
          setSuperannuationData({
            ...superannuationData,
            actuarialLife: "True",
            lifeExpectancy: retVal,
          });
          setIsCheckingLife(false);
        })
        .catch((err) => {
          console.log(err);
          setSuperannuationData({
            ...superannuationData,
            actuarialLife: "True",
          });
          setIsCheckingLife(false);
        });
    } else {
      setSuperannuationData({ ...superannuationData, actuarialLife: "False" });
    }
  };

  const handleSuperannuationData = (dataKey, dataValue) => {
    switch (dataKey) {
      case "gender":
      case "find":
        setSuperannuationData({ ...superannuationData, [dataKey]: dataValue });
        break;
      case "actuarialLife":
        setLifeExpectancy();
        break;
      case "salarySacrificeType":
        setPercentMode("salarySacrifice", dataKey);
        break;
      case "personalDeductableType":
        setPercentMode("personalContribution", dataKey);
        break;
      case "personalNonDeductableType":
        setPercentMode("personalTaxFreeContribution", dataKey);
        break;
      case "retirementLumpSumType":
        setPercentMode("lumpSum", dataKey);
        break;
      case "superFeeType":
        setPercentMode("superFees", dataKey);
        break;
      case "salarySacrifice":
        setDataValue("salarySacrificeType", dataKey, dataValue);
        break;
      case "personalContribution":
        setDataValue("personalDeductableType", dataKey, dataValue);
        break;
      case "personalTaxFreeContribution":
        setDataValue("personalNonDeductableType", dataKey, dataValue);
        break;
      case "lumpSum":
        setDataValue("retirementLumpSumType", dataKey, dataValue);
        break;
      case "superFees":
        setDataValue("superFeeType", dataKey, dataValue);
        break;
      default:
        setDataValue("", dataKey, dataValue);
        break;
    }
  };

  const handleChangeBirthday = (newValue) => {
    setSuperannuationData({
      ...superannuationData,
      dateOfBirth: moment(newValue).format("YYYY-MM-DD"),
    });
  };

  // const initializeChartData = () => {
  //   setXAxisData([]);
  //   setYAxisData1([]);
  //   setYAxisData2([]);
  //   setYAxisData3([]);
  //   setIsShowResults(false);
  // };

  const handleCalculate = () => {
    setIsCalculating(true);

    const postData = { data: superannuationData };

    axios
      .post(`${API_BASE_URL}/chat/get-superannuation`, postData)
      .then((res) => {
        const retVal = JSON.parse(res.data);
        setResultVal(retVal.result.toFixed(3));

        const xData = [];
        const yData1 = [];
        const yData2 = new Array(retVal.real.length - 1).fill(null);
        const yData3 = new Array(retVal.post.length - 1).fill(null);

        retVal.real.forEach((item, index) => {
          if (index !== retVal.pre.length) {
            xData.push(item[0]);
            yData1.push(item[1]);
          }
        });

        retVal.pre.forEach((item) => {
          const idx = xData.indexOf(item[0]);
          if (idx >= 0) {
            yData2.splice(idx, 1, item[1]);
          }
        });

        retVal.post.forEach((item) => {
          const idx = xData.indexOf(item[0]);
          if (idx >= 0) {
            yData3.splice(idx, 1, item[1]);
          }
        });

        setXAxisData(xData);
        setYAxisData1(yData1);
        setYAxisData2(yData2);
        setYAxisData3(yData3);
        setIsCalculating(false);
        setIsShowResults(true);
      })
      .catch((err) => {
        console.log(err);
        setResultVal("0");
        setIsCalculating(false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 2,
        maxWidth: "800px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Gender
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <FormControl
                sx={{
                  width: "100%",
                }}
                size="small"
              >
                <Select
                  value={superannuationData.gender}
                  onChange={(event) =>
                    handleSuperannuationData("gender", event.target.value)
                  }
                >
                  {GenderOptions.map((genderOpt) => (
                    <MenuItem value={genderOpt.value} key={genderOpt.value}>
                      <Typography color="textPrimary" variant="body2">
                        {genderOpt.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Date of Birth
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <DatePicker
                value={moment(superannuationData.dateOfBirth)}
                sx={{ "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                onChange={handleChangeBirthday}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Current Age
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={
                  new Date().getFullYear() -
                  parseInt(superannuationData.dateOfBirth.split("-")[0], 10)
                }
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Preservation Age
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={60}
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Life expectancy *
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={superannuationData.lifeExpectancy}
                  onChange={(event) =>
                    handleSuperannuationData(
                      "lifeExpectancy",
                      event.target.value
                    )
                  }
                  disabled={
                    BooleanOptions.filter(
                      (booleanOpt) =>
                        booleanOpt.value === superannuationData.actuarialLife
                    )[0].case
                  }
                  error={
                    !BooleanOptions.filter(
                      (booleanOpt) =>
                        booleanOpt.value === superannuationData.actuarialLife
                    )[0].case && isdataError("", "lifeExpectancy")
                  }
                  helperText={
                    !BooleanOptions.filter(
                      (booleanOpt) =>
                        booleanOpt.value === superannuationData.actuarialLife
                    )[0].case && dataErrorText("", "lifeExpectancy")
                  }
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "600px",
                    ml: 3,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          BooleanOptions.filter(
                            (booleanOpt) =>
                              booleanOpt.value ===
                              superannuationData.actuarialLife
                          )[0].case
                        }
                        onChange={(event) =>
                          handleSuperannuationData(
                            "actuarialLife",
                            event.target.value
                          )
                        }
                        loading={isCheckingLife}
                      />
                    }
                    label="Actuarial life expectancy"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Retirement age *
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={superannuationData.retirementAge}
                onChange={(event) =>
                  handleSuperannuationData("retirementAge", event.target.value)
                }
                error={isdataError("", "retirementAge")}
                helperText={dataErrorText("", "retirementAge")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Current taxed super balance ($) *
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={superannuationData.currentBalanceTaxed}
                onChange={(event) =>
                  handleSuperannuationData(
                    "currentBalanceTaxed",
                    event.target.value
                  )
                }
                error={isdataError("", "currentBalanceTaxed")}
                helperText={dataErrorText("", "currentBalanceTaxed")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Current untaxed super balance ($) *
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={superannuationData.currentBalanceUntaxed}
                onChange={(event) =>
                  handleSuperannuationData(
                    "currentBalanceUntaxed",
                    event.target.value
                  )
                }
                error={isdataError("", "currentBalanceUntaxed")}
                helperText={dataErrorText("", "currentBalanceUntaxed")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Current tax free super balance ($) *
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={superannuationData.currentBalanceTaxfree}
                onChange={(event) =>
                  handleSuperannuationData(
                    "currentBalanceTaxfree",
                    event.target.value
                  )
                }
                error={isdataError("", "currentBalanceTaxfree")}
                helperText={dataErrorText("", "currentBalanceTaxfree")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Current super balance ($)
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={
                  parseFloat(superannuationData.currentBalanceTaxed) +
                  parseFloat(superannuationData.currentBalanceUntaxed) +
                  parseFloat(superannuationData.currentBalanceTaxfree)
                }
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Current taxable income ($) *
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={superannuationData.currentIncome}
                onChange={(event) =>
                  handleSuperannuationData("currentIncome", event.target.value)
                }
                error={isdataError("", "currentIncome")}
                helperText={dataErrorText("", "currentIncome")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Expected percentage income growth (%) *
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={superannuationData.regularIncomeInc}
                onChange={(event) =>
                  handleSuperannuationData(
                    "regularIncomeInc",
                    event.target.value
                  )
                }
                error={isdataError("", "regularIncomeInc")}
                helperText={dataErrorText("", "regularIncomeInc")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Employer contribution (%) *
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={superannuationData.employerContribution}
                onChange={(event) =>
                  handleSuperannuationData(
                    "employerContribution",
                    event.target.value
                  )
                }
                error={isdataError("", "employerContribution")}
                helperText={dataErrorText("", "employerContribution")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Inflation (%) *
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={superannuationData.inflation}
                onChange={(event) =>
                  handleSuperannuationData("inflation", event.target.value)
                }
                error={isdataError("", "inflation")}
                helperText={dataErrorText("", "inflation")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Lump sum income (tax free) *
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={superannuationData.lumpSumIncomeChange}
                onChange={(event) =>
                  handleSuperannuationData(
                    "lumpSumIncomeChange",
                    event.target.value
                  )
                }
                error={isdataError("", "lumpSumIncomeChange")}
                helperText={dataErrorText("", "lumpSumIncomeChange")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Years until lump sum added *
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={superannuationData.lumpSumYearStart}
                onChange={(event) =>
                  handleSuperannuationData(
                    "lumpSumYearStart",
                    event.target.value
                  )
                }
                error={isdataError("", "lumpSumYearStart")}
                helperText={dataErrorText("", "lumpSumYearStart")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Lump sum rollover (taxable) *
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={superannuationData.lumpSumTaxableRollover}
                onChange={(event) =>
                  handleSuperannuationData(
                    "lumpSumTaxableRollover",
                    event.target.value
                  )
                }
                error={isdataError("", "lumpSumTaxableRollover")}
                helperText={dataErrorText("", "lumpSumTaxableRollover")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Years until lump sum rollover added *
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={superannuationData.lumpSumTaxableRolloverStart}
                onChange={(event) =>
                  handleSuperannuationData(
                    "lumpSumTaxableRolloverStart",
                    event.target.value
                  )
                }
                error={isdataError("", "lumpSumTaxableRolloverStart")}
                helperText={dataErrorText("", "lumpSumTaxableRolloverStart")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Max contribution *
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={superannuationData.maxContribution}
                onChange={(event) =>
                  handleSuperannuationData(
                    "maxContribution",
                    event.target.value
                  )
                }
                error={isdataError("", "maxContribution")}
                helperText={dataErrorText("", "maxContribution")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                {`Salary sacrifice (${
                  BooleanOptions.filter(
                    (booleanOpt) =>
                      booleanOpt.value ===
                      superannuationData.salarySacrificeType
                  )[0].case
                    ? "%"
                    : "$"
                }) *`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={superannuationData.salarySacrifice}
                  onChange={(event) =>
                    handleSuperannuationData(
                      "salarySacrifice",
                      event.target.value
                    )
                  }
                  error={isdataError("salarySacrificeType", "salarySacrifice")}
                  helperText={dataErrorText(
                    "salarySacrificeType",
                    "salarySacrifice"
                  )}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "600px",
                    ml: 3,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          BooleanOptions.filter(
                            (booleanOpt) =>
                              booleanOpt.value ===
                              superannuationData.salarySacrificeType
                          )[0].case
                        }
                        onChange={(event) =>
                          handleSuperannuationData(
                            "salarySacrificeType",
                            event.target.value
                          )
                        }
                      />
                    }
                    label="Percentage salary sacrifice"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                {`Personal deductable contribution (${
                  BooleanOptions.filter(
                    (booleanOpt) =>
                      booleanOpt.value ===
                      superannuationData.personalDeductableType
                  )[0].case
                    ? "%"
                    : "$"
                }) *`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={superannuationData.personalContribution}
                  onChange={(event) =>
                    handleSuperannuationData(
                      "personalContribution",
                      event.target.value
                    )
                  }
                  error={isdataError(
                    "personalDeductableType",
                    "personalContribution"
                  )}
                  helperText={dataErrorText(
                    "personalDeductableType",
                    "personalContribution"
                  )}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "600px",
                    ml: 3,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          BooleanOptions.filter(
                            (booleanOpt) =>
                              booleanOpt.value ===
                              superannuationData.personalDeductableType
                          )[0].case
                        }
                        onChange={(event) =>
                          handleSuperannuationData(
                            "personalDeductableType",
                            event.target.value
                          )
                        }
                      />
                    }
                    label="Percentage personal deductable"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                {`Personal non-deductable contribution (${
                  BooleanOptions.filter(
                    (booleanOpt) =>
                      booleanOpt.value ===
                      superannuationData.personalNonDeductableType
                  )[0].case
                    ? "%"
                    : "$"
                }) *`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={superannuationData.personalTaxFreeContribution}
                  onChange={(event) =>
                    handleSuperannuationData(
                      "personalTaxFreeContribution",
                      event.target.value
                    )
                  }
                  error={isdataError(
                    "personalNonDeductableType",
                    "personalTaxFreeContribution"
                  )}
                  helperText={dataErrorText(
                    "personalNonDeductableType",
                    "personalTaxFreeContribution"
                  )}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "600px",
                    ml: 3,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          BooleanOptions.filter(
                            (booleanOpt) =>
                              booleanOpt.value ===
                              superannuationData.personalNonDeductableType
                          )[0].case
                        }
                        onChange={(event) =>
                          handleSuperannuationData(
                            "personalNonDeductableType",
                            event.target.value
                          )
                        }
                      />
                    }
                    label="Percentage personal non-deductable"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                {`Lump sum (${
                  BooleanOptions.filter(
                    (booleanOpt) =>
                      booleanOpt.value ===
                      superannuationData.retirementLumpSumType
                  )[0].case
                    ? "%"
                    : "$"
                }) *`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={superannuationData.lumpSum}
                  onChange={(event) =>
                    handleSuperannuationData("lumpSum", event.target.value)
                  }
                  error={isdataError("retirementLumpSumType", "lumpSum")}
                  helperText={dataErrorText("retirementLumpSumType", "lumpSum")}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "600px",
                    ml: 3,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          BooleanOptions.filter(
                            (booleanOpt) =>
                              booleanOpt.value ===
                              superannuationData.retirementLumpSumType
                          )[0].case
                        }
                        onChange={(event) =>
                          handleSuperannuationData(
                            "retirementLumpSumType",
                            event.target.value
                          )
                        }
                      />
                    }
                    label="Percentage lump sum"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Superannuation tax rate (%) *
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={superannuationData.superannuationTaxRate}
                onChange={(event) =>
                  handleSuperannuationData(
                    "superannuationTaxRate",
                    event.target.value
                  )
                }
                error={isdataError("", "superannuationTaxRate")}
                helperText={dataErrorText("", "superannuationTaxRate")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                {`Super fees (${
                  BooleanOptions.filter(
                    (booleanOpt) =>
                      booleanOpt.value === superannuationData.superFeeType
                  )[0].case
                    ? "%"
                    : "$"
                }) *`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={superannuationData.superFees}
                  onChange={(event) =>
                    handleSuperannuationData("superFees", event.target.value)
                  }
                  error={isdataError("superFeeType", "superFees")}
                  helperText={dataErrorText("superFeeType", "superFees")}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "600px",
                    ml: 3,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          BooleanOptions.filter(
                            (booleanOpt) =>
                              booleanOpt.value ===
                              superannuationData.superFeeType
                          )[0].case
                        }
                        onChange={(event) =>
                          handleSuperannuationData(
                            "superFeeType",
                            event.target.value
                          )
                        }
                      />
                    }
                    label="Super fee type"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Super growth (%) *
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={superannuationData.superGrowth}
                onChange={(event) =>
                  handleSuperannuationData("superGrowth", event.target.value)
                }
                error={isdataError("", "superGrowth")}
                helperText={dataErrorText("", "superGrowth")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              p: 1,
              borderRadius: "9px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Find
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <FormControl
                sx={{
                  width: "100%",
                }}
                size="small"
              >
                <Select
                  value={superannuationData.find}
                  onChange={(event) =>
                    handleSuperannuationData("find", event.target.value)
                  }
                >
                  {FindOptions.map((findOpt) => (
                    <MenuItem value={findOpt.value} key={findOpt.value}>
                      <Typography color="textPrimary" variant="body2">
                        {findOpt.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          {superannuationData.find === FindOptions[0].value && (
            <Box
              sx={{
                mx: 2,
                p: 1,
                borderRadius: "9px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "50%",
                }}
              >
                <Typography variant="body1" color="textSecondary">
                  Annuity *
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "50%",
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={superannuationData.annuity}
                  onChange={(event) =>
                    handleSuperannuationData("annuity", event.target.value)
                  }
                  error={isdataError("", "annuity")}
                  helperText={dataErrorText("", "annuity")}
                />
              </Box>
            </Box>
          )}
          <Box
            sx={{
              width: "100%",
              mt: 3,
            }}
          >
            <Button
              className="btn-tax-calculate"
              variant="contained"
              onClick={handleCalculate}
              disabled={isCalculating || someDataError}
            >
              {isCalculating && <CircularProgress size={20} />}
              Calculate
            </Button>
          </Box>
        </Box>
      </Box>

      {isShowResults && (
        <Box
          sx={{
            mt: 2,
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                px: 2,
              }}
            >
              <Box
                sx={{
                  p: 1,
                  borderRadius: "9px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "50%",
                  }}
                >
                  <Typography variant="body1" color="textSecondary">
                    {`Result (${
                      superannuationData.find === FindOptions[0].value
                        ? "years"
                        : "$"
                    })`}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "50%",
                  }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={resultVal}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
              height: "calc(100% - 72px)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <Chart
                height="600"
                options={chartOptions}
                series={data.series}
                type="line"
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Superannuation;
