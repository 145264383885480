import Drawer from "react-modern-drawer";
import { Box, FormControlLabel, Radio, RadioGroup, Checkbox, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import SearchOption from "./SearchOption";
import SpecificSearch from "./SpecificSearch";
import LegalInfo from "./LegalInfo";
import ModelOptions from "./ModelOptions";
import FastSearch from "./FastSearch";
import { useEffect } from "react";
import ToolTip from "./ToolTip";

const AdvancedSearch = ({ open, defaultSearch, setDefaultSearch, dataSource, setDataSource, model, setModel, hierarchy, setHierarchy, legalInfo, setLegalInfo, fastSearch, setFastSearch, toolTip,setToolTip, onClose }) => {
    const handleChange = (event) => {
        setDefaultSearch(event);
        if (event == true) {
            setDataSource("azureS")
            setModel("gpt-4o")
            setHierarchy(false)
            setLegalInfo("false")
            setFastSearch(null)
            setToolTip('false')

        }
    };

    useEffect(() => {
        console.log(defaultSearch, "defaultSearch")
    }, [defaultSearch])

    useEffect(() => {
        if (fastSearch) {
            setDataSource(null)
            setModel(null)
            setHierarchy(false)
            setLegalInfo(null)
            setDefaultSearch(false)
            setToolTip(null)
        }
        else {
            if (!dataSource) {
                setDataSource("azureS")
            }
            if (!model) {
                setModel("gpt-4o")
            }

            if (!legalInfo) {
                setLegalInfo("false")

            }
        }

    }, [fastSearch])

    // useEffect(()=>{

    //     if (hierarchy === false && model === "gpt-4o" && legalInfo === "false"  && fastSearch === null) {
    //         console.log("hello", model, "hello", hierarchy, "model", legalInfo, "dataSource", dataSource, "fast search", fastSearch);
    //         setDefaultSearch(true);
    //     }

    // },[model,hierarchy,legalInfo,dataSource,fastSearch])
    return (

        <>


            <Drawer open={open} onClose={onClose} direction="right" className="custom-drawer-wrapper" style={{ border: "1px solid #2895EB", top: "100px"}} >
                <div className="custom-drawer ">
                    <div className="left-side">
                        <Box className="drawer-title">Advanced Search</Box>
                        {/* <HierrachyTree selectedIds={selectedIds} setSelectedIds={setSelectedIds} setSelectedPaths={setSelectedPaths} /> */}
                        <RadioGroup sx={{ py: 1, pl: 2 }} value={defaultSearch} onChange={handleChange}>
                            <FormControlLabel
                                sx={{ display: 'flex', alignItems: 'center' }}
                                label={

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Default</span>

                                        <Tooltip title="Default Search uses Azure semantic, with gpt 4o as the model, and legal info is disabled" placement="top">
                                            <InfoIcon sx={{ ml: 1 }} />
                                        </Tooltip>
                                    </div>
                                }
                                control={
                                    <Checkbox
                                        sx={{ "&.MuiCheckbox-indeterminate svg": { color: "grey" } }}
                                        checked={defaultSearch}
                                        onChange={(event) => handleChange(event.target.checked)}
                                        disabled={defaultSearch}
                                    />
                                }
                            />
                        </RadioGroup>
                        {/* <SearchOption
                            dataSource={dataSource} setDataSource={setDataSource} model={model} setModel={setModel} defaultSearch={defaultSearch} handleDefaultSearch={handleChange} setFastSearch={setFastSearch} /> */}

                    </div>

                    <div className="right-side">
                        <LegalInfo legalInfo={legalInfo} setLegalInfo={setLegalInfo} defaultSearch={defaultSearch} handleDefaultSearch={handleChange} dataSource={dataSource} setFastSearch={setFastSearch} />
                        <ModelOptions model={model} setModel={setModel} defaultSearch={defaultSearch} handleDefaultSearch={handleChange} setFastSearch={setFastSearch} />
                        <ToolTip toolTip={toolTip} setToolTip={setToolTip} defaultSearch={defaultSearch} handleDefaultSearch={handleChange} dataSource={dataSource} setFastSearch={setFastSearch} />
                    </div>



                    
                </div>
                {/* <div className="w-100 fastsearch">
                    <FastSearch setFastSearch={setFastSearch} fastSearch={fastSearch} />
                </div> */}
            </Drawer>

        </>
    );
};

export default AdvancedSearch;
