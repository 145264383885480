import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
const ModelOptions = ({ model, setModel, defaultSearch, handleDefaultSearch, setFastSearch }) => {

    const handleModelChange = (event) => {
        if (defaultSearch)
            handleDefaultSearch(false)
        setModel(event.target.value);
        setFastSearch(null)

    };


    return (
        <>
            <Box className="drawer-title">Model Option </Box>

            {/* <h3 style={{ marginLeft: '15px', marginBottom: '0px' }}>Models</h3> */}
            <RadioGroup
                sx={{
                    py: 1,
                    pl: 2,
                    display: 'flex',
                    flexDirection: 'row', // To align items horizontally
                    justifyContent: 'flex-start', // To align items at the start
                }}
                value={model}
                onChange={handleModelChange}
            >
                <FormControlLabel value="gpt-4" control={<Radio />} label="GPT-4" sx={{ mr: 1 }} />
                <FormControlLabel value="gpt-4o" control={<Radio />} label={<Box sx={{ display: 'flex', alignItems: 'center' }}>
                    GPT-4o &nbsp; <Tooltip title="GPT-4: This version is designed to handle a wide range of tasks by understanding and generating text that closely resembles human language. It’s versatile and effective for answering questions, providing explanations, and creating various types of content. Think of it as a general-purpose tool that works well for most needs.

GPT-4o: This version is a refined or specialized variant of the main model. It has been enhanced to perform exceptionally well in specific areas, such as summarizing information or handling particular types of requests more efficiently. While the main version is a great all-rounder, this specialized version is optimized for delivering better results in certain tasks or topics.

Switching between these versions allows you to choose the best option depending on whether you need broad support or more tailored responses for specific needs."><InfoIcon sx={{ mr: 1 }} /></Tooltip></Box>} sx={{ mr: 1 }} />
            </RadioGroup>
        </>)

}

export default ModelOptions