import "../../../styles/components/browse/Sidebar.css";
import "../../../styles/components/browse/History.css";
import { Button } from "@mui/material";
import SidebarSkeleton from "../../skeleton/SidebarSkeleton";

const Sidebar = ({ container, setContainer, isLoading, histories }) => {
    return (
        <div className="browse-sidebar">
            <div className="switch-container">
                <div className="btn-group">
                    <Button className={container === "Chat" ? "btn-chat active" : "btn-chat"} onClick={() => setContainer("Chat")}>
                        Chat
                    </Button>
                    <Button className={container === "Browse" ? "btn-browse active" : "btn-browse"} onClick={() => setContainer("Browse")}>
                        Search
                    </Button>
                    <Button className={container === "ScratchPad" ? "btn-scratch active" : "btn-scratch"} onClick={() => setContainer("ScratchPad")}>
                        Scratch
                    </Button>

                    <Button className={container === "ReverseSearch" ? "btn-reverse active" : "btn-reverse"} onClick={() => setContainer("ReverseSearch")}>
                        Reverse Search
                    </Button>
                </div>
            </div>
            {/* <div className="browse-history-container">
                {isLoading ? <SidebarSkeleton /> : histories}
            </div> */}
        </div>
    );
}

export default Sidebar