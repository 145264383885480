import React, { useState } from "react";
import "../../../styles/components/chat/Content.css";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { getTitle, getHierrachy } from "../../../utils/utils";
import HPInterestRate from "../../calculator/HPInterestRate";
import HPAnalyzer from "../../calculator/HPAnalyzer";
import Superannuation from "../../calculator/Superannuation";

import { ReactComponent as Workspaces } from "../../../images/workspaces.svg";
import { ReactComponent as ArrowForward } from "../../../images/arrow-forward.svg";
import { Tooltip } from "@mui/material";
import PromptExamples from "./PromptExamples";
import ChatFeedBack from "./ChatFeedback";
import LoanDetails from "../../calculator/LoadDetails";

const ReferencesContainer = ({ dataSource, references, getSummary }) => {
  const formatUrls = (refernceInfo) => {
    if (dataSource === "google") {
      return refernceInfo;
    }

    const data = {};

    for (const reference of refernceInfo) {
      const titleArray = reference?.hierrachy?.split(" > ");
      if(titleArray){
      const subtitle =
        titleArray[0] === "ATO general website" ? titleArray[1] : titleArray[0];
      if (!data[subtitle]) {
        data[subtitle] = [];
      }
      data[subtitle].push({
        ...reference,
        title: getTitle(reference.hierrachy),
        hierrachy: getHierrachy(reference.hierrachy),
      });
    }
    }

    return data;
  };
  console.log(references, "References")

  if (references) {
    var refInfo = formatUrls(references);
  }

  return (
    <div className="chat-content-references-container">
      <div
        className="chat-content-references-header"
        style={{ marginBottom: dataSource === "google" ? "12px" : "0px" }}
      >
        References
      </div>
      <div className="chat-content-references-content">
        {dataSource === "google"
          ? refInfo.map((refernce, index) => (
            <GoogleReferenceItem
              key={index}
              reference={refernce}
              getSummary={getSummary}
              dataSource={dataSource}
            />
          ))
          : Object.entries(refInfo).map(([title, refs], index) => (
            <div key={index}>
              <AtoReferenceItem
                title={title}
                references={refs}
                getSummary={getSummary}
                dataSource={dataSource == "weaviate" ? "ato" : dataSource}
              />
              {index !== Object.keys(refInfo).length - 1 && (
                <div className="chat-content-refernce-divider"></div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

const AtoReferenceItem = ({ title, references, getSummary, dataSource }) => {
  const [isSummarizing, setIsSummarizing] = useState(false);
  const handleSummary = async (reference) => {
    setIsSummarizing(true);
    await getSummary(reference.url, reference.title, dataSource);
    setIsSummarizing(false);
  };
  const handleOpen = (reference) => {
    window.open(reference.url, "_blank");
  };

  return (
    <>
      <div className="chat-content-refernce-sub-title">{title}</div>
      <div className="chat-content-referencs">
        {references.length > 0 &&
          references.map((reference, index) => (
            <div className="chat-content-refernce-item" key={index}>
              <div className="chat-content-reference-hierrachy">
                {reference.hierrachy}
              </div>
              <div className="chat-content-reference-button-group">
                <Tooltip title="Summarize" placement="top">
                  <button
                    className="chat-content-reference-button"
                    onClick={() => handleSummary(reference)}
                    disabled={isSummarizing}
                  >
                    <Workspaces />
                  </button>
                </Tooltip>
                <Tooltip title={reference.url} placement="top">
                  <button
                    className="chat-content-reference-button"
                    onClick={() => handleOpen(reference)}
                  >
                    <ArrowForward />
                  </button>
                </Tooltip>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

const GoogleReferenceItem = ({ reference, getSummary, dataSource }) => {
  const [isSummarizing, setIsSummarizing] = useState(false);

  const handleSummary = async (ref) => {
    setIsSummarizing(true);
    await getSummary(ref.url, ref.title, dataSource);
    setIsSummarizing(false);
  };
  const handleOpen = (ref) => {
    window.open(ref.url, "_blank");
  };

  return (
    <div className="chat-content-refernce-item">
      <div className="chat-content-reference-hierrachy">{reference.title}</div>
      <div className="chat-content-reference-button-group">
        <Tooltip title="Summarize" placement="top">
          <button
            className="chat-content-reference-button"
            onClick={() => handleSummary(reference)}
            disabled={isSummarizing}
          >
            <Workspaces />
          </button>
        </Tooltip>
        <Tooltip title={reference.url} placement="top">
          <button
            className="chat-content-reference-button"
            onClick={() => handleOpen(reference)}
          >
            <ArrowForward />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

const AnswerMessage = ({ answerMsg, getSummary, setChatText }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="chat-content-ai">
        <MarkdownPreview
          className="chat-content-ai-text"
          source={answerMsg?.prompt}
          wrapperElement={{
            "data-color-mode": "light",
          }}
        ></MarkdownPreview>
        {answerMsg.metainfo.functionName ===
          "get_hire_purchase_interest_rate" && <HPInterestRate />}
        {answerMsg.metainfo.functionName === "get_hire_purchase_analyzer" && (
          <HPAnalyzer />
        )}
        {answerMsg.metainfo.functionName === "get_superannuation" && (
          <Superannuation />
        )}
        {answerMsg.metainfo.functionName === "calc_division_7a_loan" && (
          <LoanDetails />
        )}
        <ChatFeedBack promptId={answerMsg.id} />
        {answerMsg.metainfo.functionName === "none" && (
          <>
            {answerMsg.metainfo.urls.length > 0 && (
              <ReferencesContainer
                dataSource={answerMsg.metainfo.dataSource == "weaviate" ? "ato" : answerMsg.metainfo.dataSource}
                references={answerMsg.metainfo.urls}
                getSummary={getSummary}
              />
            )}
            {answerMsg.metainfo.prompts &&
              answerMsg.metainfo.prompts.length > 0 && (
                <PromptExamples
                  prompts={answerMsg.metainfo.prompts}
                  setChatText={setChatText}
                />
              )}
          </>
        )}
      </div>
    </LocalizationProvider>
  );
};

export default AnswerMessage;
