import Drawer from "react-modern-drawer";
import { Box, FormControlLabel, Radio, RadioGroup, Checkbox, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

import HierrachyTree from "./HierrachyTree";
import { useEffect } from "react";


const SpecificSearch = ({ setHierarchy, hierarchy, defaultSearch, handleDefaultSearch, dataSource, setFastSearch }) => {
    const handleChange = (checked) => {
        setHierarchy(checked);
        setFastSearch(null);
        if (defaultSearch) {
            handleDefaultSearch(false)
        }
    };

    useEffect(() => {
        console.log(hierarchy, "Hierarchy")
        if (dataSource === 'google')
            setHierarchy(false)
    }, [dataSource])

    return (

        <>
            <Box className="drawer-title">Specific Search
            </Box>

            <RadioGroup sx={{ py: 1, pl: 2 }} value={hierarchy} onChange={handleChange}>
                <FormControlLabel
                    sx={{ display: 'flex', alignItems: 'center' }}
                    label={

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>Company tax return instructions 2023</span>

                            <Tooltip title="Specific Search for Company tax return instructions 2023." placement="top">
                                <InfoIcon sx={{ ml: 1 }} />
                            </Tooltip>
                        </div>
                    }
                    control={
                        <Checkbox
                            sx={{ "&.MuiCheckbox-indeterminate svg": { color: "grey" } }}
                            checked={hierarchy}
                            onChange={(event) => handleChange(event.target.checked)}
                            disabled={dataSource!='google'?false:true}

                        />
                    }
                />




            </RadioGroup>
        </>

    );
};

export default SpecificSearch;
