import { ReactComponent as CalendarCheck } from "../../../images/calendar-check.svg";
import { ReactComponent as Legal } from "../../../images/legal.svg";
import { ReactComponent as BlockShield } from "../../../images/block-shield.svg";

const Introduction = ({ isTermsAgree, setIsTermsAgree,scratchText }) => {
  const handleTermsAgree = () => {
    setIsTermsAgree(true);
  };

  return (
    <>

      <h3 style ={{textAlign:'center', marginBottom:'20px', whiteSpace:'pre-wrap'}}>Scratch Pad</h3>
      <div className="">
        <div className="chat-introduction" style={{whiteSpace:'pre-wrap'}}>
            {scratchText?scratchText:"Empty Scratch Pad"}
        </div>
        {/* {!isTermsAgree && (
          <div className="chat-terms">
            Some things to consider before we start:
            <div className="chat-terms-info">
              <div className="chat-term-item">
                <div className="chat-term-item-icon">
                  <CalendarCheck />
                </div>
                <div className="chat-term-title">Verify Answers</div>
                <div className="chat-term-content">Please verify all answers for accuracy. Data sources are provided.</div>
              </div>
              <div className="chat-term-item">
                <div className="chat-term-item-icon">
                  <Legal />
                </div>
                <div className="chat-term-title">Not legal advice</div>
                <div className="chat-term-content">Answers given by Tax Genii do not constitute legal advice.</div>
              </div>
              <div className="chat-term-item">
                <div className="chat-term-item-icon">
                  <BlockShield />
                </div>
                <div className="chat-term-title">No sensitive data</div>
                <div className="chat-term-content">Do not share sensitive data. This is a public beta and all data is public.</div>
              </div>
            </div>
            <div className="chat-terms-agree">
              <div className="chat-terms-agree-description">If you agree to the above, please press the button to continue:</div>
              <button className="chat-terms-agree-button" onClick={handleTermsAgree}>
                YES
              </button>
            </div>
          </div>
        )} */}
      </div>
    </>
  );
};

export default Introduction;
