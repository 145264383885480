import { ReactComponent as CalendarCheck } from "../../../images/calendar-check.svg";
import { ReactComponent as Legal } from "../../../images/legal.svg";
import { ReactComponent as BlockShield } from "../../../images/block-shield.svg";
import { useSearchParams } from "react-router-dom";
const Introduction = ({ isTermsAgree, setIsTermsAgree }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const handleTermsAgree = () => {
    setIsTermsAgree(true);
  };

  return (
    <>

      {searchParams.get('fastSearch') ?

        <div className="chat-introduction-container">
          <div className="chat-introduction">
            Hi,
            <br />
            <br />
            I’m Tax Genii, an AI powered tax assistant to help Australian accountants, lawyers and companies with tax research.
            <br/>
            <br/>
            Query to Search against <b>{searchParams.get('fastSearch')}.</b>
          </div>
        </div> :
        <div className="chat-introduction-container">

          <div className="chat-introduction">
            Hi,
            <br />
            <br />
            I’m Tax Genii, an AI powered tax assistant to help Australian accountants, lawyers and companies with tax research. Unlike Chat GPT which was trained on data up to September 2021, Tax Genii has access to current tax information including case law, ATO guidance, rulings & public advice.
            <br />
            Please be patient. Some queries can take time to complete the search and summarise process.
            <br />
            <br />
          </div>
          {!isTermsAgree && (
            <div className="chat-terms">
              Some things to consider before we start:
              <div className="chat-terms-info">
                <div className="chat-term-item">
                  <div className="chat-term-item-icon">
                    <CalendarCheck />
                  </div>
                  <div className="chat-term-title">Verify Answers</div>
                  <div className="chat-term-content">Please verify all answers for accuracy. Data sources are provided.</div>
                </div>
                <div className="chat-term-item">
                  <div className="chat-term-item-icon">
                    <Legal />
                  </div>
                  <div className="chat-term-title">Not legal advice</div>
                  <div className="chat-term-content">Answers given by Tax Genii do not constitute legal advice.</div>
                </div>
                <div className="chat-term-item">
                  <div className="chat-term-item-icon">
                    <BlockShield />
                  </div>
                  <div className="chat-term-title">No sensitive data</div>
                  <div className="chat-term-content">Do not share sensitive data. This is a public beta and all data is public.</div>
                </div>
              </div>
              <div className="chat-terms-agree">
                <div className="chat-terms-agree-description">If you agree to the above, please press the button to continue:</div>
                <button className="chat-terms-agree-button" onClick={handleTermsAgree}>
                  YES
                </button>
              </div>
            </div>
          )}
        </div>
      }
    </>
  );
};

export default Introduction;
