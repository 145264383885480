import { useSearchParams } from "react-router-dom";

const samplePrompts = [
  "Company tax return instructions",
  "Trust tax return instructions",
  "Partnership tax return instructions",
  "Individual tax return instructions",
  "Fringe benefits tax return instructions",
  "Self-managed superannuation return instructions"
];

const PromptExamples = ({ prompts, setChatText, isTitle = true }) => {

  const [searchParams, setSearchParams] = useSearchParams()
  const questions = prompts && prompts.length > 0 ? prompts : samplePrompts;

  const handleAsk = (msg) => {
    // Define the URLs associated with each prompt
    const urlMap = {
      "Company tax return instructions": "/?fastSearch=Company tax return Instructions",
      "Trust tax return instructions": "/?fastSearch=Trust tax return and instructions",
      "Partnership tax return instructions": "/?fastSearch=Partnership tax return instruction",
      "Individual tax return instructions": "/?fastSearch=Individual tax return instructions",
      "Fringe benefits tax return instructions": "/?fastSearch=Fringe benefits tax return instructions",
      "Self-managed superannuation return instructions":"/?fastSearch=Self-managed superannuation fund annual return instructions"
    };
  
    // Redirect to the corresponding URL
    const targetUrl = urlMap[msg];
    if (targetUrl) {
      window.location.href = targetUrl;
    } else {
      setChatText(msg); // Fallback if no URL is mapped
    }
  };
  

  return (
    <>{!searchParams.get('chatId')  && !searchParams.get('fastSearch')&& (<div className="prompt-examples-container">
      {isTitle && (
        <div className="prompt-examples-title">Related Questions</div>
      )}
      <div className="prompt-examples">
        {questions?.map((msg, index) => (
          <div
            key={index}
            className="prompt-example"
            onClick={() => handleAsk(msg)}
          >
            {msg}
          </div>
        ))}
      </div>
    </div>)}</>
  );
};

export default PromptExamples;
