export const toastOption = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

// export const API_BASE_URL = "http://13.55.158.224:443/api";
export const API_BASE_URL = "https://api.taxgenii.lodgeit.net.au/api";
//export const API_BASE_URL = "https://b836-3-104-166-47.ngrok-free.app/api";
// export const API_BASE_URL = "https://501e-2a09-bac5-503f-101e-00-19b-1.ngrok-free.app/api";
  //export const API_BASE_URL = "https://2c60-203-82-55-94.ngrok-free.app/api"

export const LODGEIT_URL = "https://lodgeit.net.au";

export const ROLE_USER = "user";
export const ROLE_SYSTEM = "system";
export const ROLE_KNOWLEDGE = "knowledge";
export const ROLE_ASSISTANT = "assistant";
