import React, { useState } from 'react';

const styles = {
  container: {
    minHeight: '100vh',
    padding: '20px',
    backgroundColor: '#f0f2f5'
  },
  card: {
    maxWidth: '800px',
    margin: '0 auto',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    padding: '20px'
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#333'
  },
  form: {
    display: 'grid',
    gap: '20px'
  },
  inputGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '15px'
  },
  field: {
    marginBottom: '10px'
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#444'
  },
  input: {
    width: '100%',
    padding: '8px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '14px'
  },
  select: {
    width: '100%',
    padding: '8px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '14px'
  },
  button: {
    backgroundColor: '#007bff',
    color: 'white',
    padding: '10px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%'
  },
  disabledButton: {
    backgroundColor: '#ccc',
    cursor: 'not-allowed'
  },
  error: {
    marginTop: '15px',
    padding: '10px',
    backgroundColor: '#ffebee',
    border: '1px solid #ffcdd2',
    borderRadius: '4px',
    color: '#c62828'
  },
  result: {
    marginTop: '20px',
    padding: '15px',
    backgroundColor: '#e8f5e9',
    border: '1px solid #c8e6c9',
    borderRadius: '4px',
    color: '#2e7d32'
  },
  balloonSection: {
    marginTop: '20px',
    padding: '15px',
    border: '1px solid #ddd',
    borderRadius: '4px'
  },
  balloonTitle: {
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '15px'
  },
  balloonInputs: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '10px',
    marginBottom: '15px'
  },
  balloonList: {
    marginTop: '15px'
  },
  balloonItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: '#f8f9fa',
    borderRadius: '4px',
    marginBottom: '5px'
  },
  removeButton: {
    backgroundColor: '#dc3545',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '5px 10px',
    cursor: 'pointer'
  },
  addButton: {
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '8px',
    cursor: 'pointer'
  }
};

const API_BASE_URL = 'https://cb03-54-66-223-206.ngrok-free.app';

const LoanCalculator = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [result, setResult] = useState(null);
  const [formData, setFormData] = useState({
    asset_name: '',
    first_payment_date: '',
    loan_amount: [''],
    num_regular_payments: [''],
    payment_type: '',
    period: '',
    regular_payment: [''],
    balloon_payments: []
  });

  const [balloonAmount, setBalloonAmount] = useState('');
  const [balloonDate, setBalloonDate] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    
    try {
      const response = await fetch(`${API_BASE_URL}/calculate-interest`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
      
      if (!response.ok) {
        throw new Error('Failed to calculate interest rate');
      }
      
      const data = await response.json();
      setResult(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'loan_amount' || name === 'regular_payment') {
      setFormData(prev => ({
        ...prev,
        [name]: [value ? parseFloat(value) : '']
      }));
    } else if (name === 'num_regular_payments') {
      setFormData(prev => ({
        ...prev,
        [name]: [value ? parseInt(value) : '']
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const addBalloonPayment = () => {
    if (balloonAmount && balloonDate) {
      setFormData(prev => ({
        ...prev,
        balloon_payments: [...prev.balloon_payments, [parseFloat(balloonAmount), balloonDate]]
      }));
      setBalloonAmount('');
      setBalloonDate('');
    }
  };

  const removeBalloonPayment = (index) => {
    setFormData(prev => ({
      ...prev,
      balloon_payments: prev.balloon_payments.filter((_, i) => i !== index)
    }));
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h1 style={styles.title}>Loan Interest Calculator</h1>
        
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.inputGroup}>
            <div style={styles.field}>
              <label style={styles.label}>Asset Name</label>
              <input
                style={styles.input}
                name="asset_name"
                value={formData.asset_name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div style={styles.field}>
              <label style={styles.label}>First Payment Date</label>
              <input
                style={styles.input}
                type="date"
                name="first_payment_date"
                value={formData.first_payment_date}
                onChange={handleInputChange}
                required
              />
            </div>
            <div style={styles.field}>
              <label style={styles.label}>Loan Amount</label>
              <input
                style={styles.input}
                type="number"
                name="loan_amount"
                value={formData.loan_amount[0]}
                onChange={handleInputChange}
                required
              />
            </div>
            <div style={styles.field}>
              <label style={styles.label}>Regular Payment</label>
              <input
                style={styles.input}
                type="number"
                name="regular_payment"
                value={formData.regular_payment[0]}
                onChange={handleInputChange}
                required
              />
            </div>
            <div style={styles.field}>
              <label style={styles.label}>Number of Payments</label>
              <input
                style={styles.input}
                type="number"
                name="num_regular_payments"
                value={formData.num_regular_payments[0]}
                onChange={handleInputChange}
                required
              />
            </div>
            <div style={styles.field}>
              <label style={styles.label}>Payment Type</label>
              <select
                style={styles.select}
                name="payment_type"
                value={formData.payment_type}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Payment Type</option>
                <option value="[IN ARREARS]">In Arrears</option>
                <option value="[IN ADVANCE]">In Advance</option>
              </select>
            </div>
            <div style={styles.field}>
              <label style={styles.label}>Period</label>
              <select
                style={styles.select}
                name="period"
                value={formData.period}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Period</option>
                <option value="[MONTHLY]">Monthly</option>
                <option value="[WEEKLY]">Weekly</option>
                <option value="[FORTNIGHTLY]">Fortnightly</option>
              </select>
            </div>
          </div>

          <div style={styles.balloonSection}>
            <h3 style={styles.balloonTitle}>Balloon Payments</h3>
            <div style={styles.balloonInputs}>
              <div style={styles.field}>
                <label style={styles.label}>Amount</label>
                <input
                  style={styles.input}
                  type="number"
                  value={balloonAmount}
                  onChange={(e) => setBalloonAmount(e.target.value)}
                  placeholder="Enter amount"
                />
              </div>
              <div style={styles.field}>
                <label style={styles.label}>Date</label>
                <input
                  style={styles.input}
                  type="date"
                  value={balloonDate}
                  onChange={(e) => setBalloonDate(e.target.value)}
                />
              </div>
              <div style={styles.field}>
                <button
                  type="button"
                  onClick={addBalloonPayment}
                  style={styles.addButton}
                >
                  Add Balloon Payment
                </button>
              </div>
            </div>

            <div style={styles.balloonList}>
              {formData.balloon_payments.length > 0 ? (
                formData.balloon_payments.map((payment, index) => (
                  <div key={index} style={styles.balloonItem}>
                    <span>Amount: ${payment[0].toLocaleString()} - Date: {payment[1]}</span>
                    <button
                      type="button"
                      onClick={() => removeBalloonPayment(index)}
                      style={styles.removeButton}
                    >
                      Remove
                    </button>
                  </div>
                ))
              ) : (
                <p>No balloon payments added</p>
              )}
            </div>
          </div>

          <button 
            type="submit" 
            disabled={loading}
            style={{
              ...styles.button,
              ...(loading ? styles.disabledButton : {})
            }}
          >
            {loading ? 'Calculating...' : 'Calculate Interest Rate'}
          </button>
        </form>

        {error && (
          <div style={styles.error}>
            {error}
          </div>
        )}

        {result && (
          <div style={styles.result}>
            <h3 style={styles.balloonTitle}>Results</h3>
            <p>Interest Rate: {result.interest_rate.toFixed(2)}%</p>
            <p>Asset: {result.asset_name}</p>
            <p>First Payment: {new Date(result.first_payment_date).toLocaleDateString()}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoanCalculator;