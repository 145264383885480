import React, { useState } from "react";
import "../../../styles/components/chat/Content.css";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { getTitle, getHierrachy } from "../../../utils/utils";
import HPInterestRate from "../../calculator/HPInterestRate";
import HPAnalyzer from "../../calculator/HPAnalyzer";
import Superannuation from "../../calculator/Superannuation";

import { ReactComponent as Workspaces } from "../../../images/workspaces.svg";
import { ReactComponent as ArrowForward } from "../../../images/arrow-forward.svg";
import { Tooltip } from "@mui/material";
import PromptExamples from "./PromptExamples";
import ChatFeedBack from "./ChatFeedback";



const AnswerMessage = ({ answerMsg }) => {
  return (
    // <LocalizationProvider dateAdapter={AdapterMoment}>
    //   <div className="chat-content-ai">
    //     <MarkdownPreview
    //       className="chat-content-ai-text"
    //       source={answerMsg.prompt}
    //       wrapperElement={{
    //         "data-color-mode": "light",
    //       }}
    //     ></MarkdownPreview>
    //     {answerMsg.metainfo.functionName ===
    //       "get_hire_purchase_interest_rate" && <HPInterestRate />}
    //     {answerMsg.metainfo.functionName === "get_hire_purchase_analyzer" && (
    //       <HPAnalyzer />
    //     )}
    //     {answerMsg.metainfo.functionName === "get_superannuation" && (
    //       <Superannuation />
    //     )}
    //     <ChatFeedBack promptId={answerMsg.id} />
    //     {answerMsg.metainfo.functionName === "none" && (
    //       <>
    //         {answerMsg.metainfo.urls.length > 0 && (
    //           <ReferencesContainer
    //             dataSource={answerMsg.metainfo.dataSource=="weaviate"?"ato":answerMsg.metainfo.dataSource}
    //             references={answerMsg.metainfo.urls}
    //             getSummary={getSummary}
    //           />
    //         )}
    //         {answerMsg.metainfo.prompts &&
    //           answerMsg.metainfo.prompts.length > 0 && (
    //             <PromptExamples
    //               prompts={answerMsg.metainfo.prompts}
    //               setChatText={setChatText}
    //             />
    //           )}
    //       </>
    //     )}
    //   </div>
    // </LocalizationProvider>

    <p>
    {answerMsg}
    </p>
  );
};

export default AnswerMessage;
