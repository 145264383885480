import React, { useEffect, useRef, useState } from 'react';
import {
  Typography,
  TextField,
  Grid,
  Box,
  FormControl,
  CircularProgress,
  Divider,
  Button,
  IconButton,
  MenuItem,
  Select
} from '@mui/material';
import Scrollbar from '../components/scrollbar/Scrollbar';
import Close from '@mui/icons-material/Close';
import axios from 'axios';
import { accsiomURIs } from './config';
import NumberField from '../components/number-field/NumberField';

const AOAOptions = [
  { label: 'Advance', value: '0' },
  { label: 'Arrears', value: '1' }
];

const PeriodOptions = [
  { label: 'Daily', value: '365' },
  { label: 'Weekly', value: '52' },
  { label: 'Fortnight', value: '26' },
  { label: 'Monthly', value: '12' }
];

const HPCalculator = React.forwardRef((props, ref) => {
  const { onClose } = props;
  const scrollRef = useRef(null);
  const [balloons, setBalloons] = useState([{ index: 0, period: '', amount: '' }]);
  const [firstPay, setFirstPay] = useState(AOAOptions[0].value);
  const [borrowedAmount, setBorrowedAmount] = useState('0');
  const [numberofPayments, setNumberofPayments] = useState('0');
  const [repaymentAmount, setRepaymentAmount] = useState('0');
  const [period, setPeriod] = useState(PeriodOptions[0].value);
  const [borrowingRate, setBorrowingRate] = useState('');
  const [isCalculating, setIsCalculating] = useState(false);

  const handleFirstPay = (event) => {
    setFirstPay(event.target.value);
  };

  const handleBorrowedAmount = (event) => {
    setBorrowedAmount(event.target.value);
  };

  const handleNumberofPayments = (event) => {
    setNumberofPayments(event.target.value);
  };

  const handleRepaymentAmount = (event) => {
    setRepaymentAmount(event.target.value);
  };

  const handlePeriod = (event) => {
    setPeriod(event.target.value);
  };

  const handleCalculate = () => {
    let reUrl = `${accsiomURIs.hpcalc_api_domain}?borrowedAmount=${borrowedAmount}&numberofPayments=${numberofPayments}&repaymentAmount=${repaymentAmount}&firstPayment=${firstPay}&period=${period}`;
    balloons.forEach((balloon) => {
      if (balloon.index === 0 || balloon.period !== '0') {
        reUrl = `${reUrl}&balloonNumber=%5B${balloon.period}%5D&balloonAmount=%5B${balloon.amount}%5D`;
      }
    });
    setIsCalculating(true);
    axios.post(reUrl)
      .then((res) => {
        let retVal = parseFloat(res.data);
        retVal *= 100;
        setBorrowingRate(`${retVal.toFixed(3)}%`);
        setIsCalculating(false);
      })
      .catch((err) => {
        console.error(err);
        setIsCalculating(false);
      });
  };

  const handleClose = () => {
    setBalloons([{ index: 0, period: '0', amount: '0' }]);
    setFirstPay(AOAOptions[0].value);
    setBorrowedAmount('0');
    setNumberofPayments('0');
    setRepaymentAmount('0');
    setPeriod(PeriodOptions[0].value);
    setBorrowingRate('');
    setIsCalculating(false);
    if (onClose) onClose();
  };

  const scrollToBottom = () => {
    if (scrollRef?.current?._container) {
      scrollRef.current._container.scrollTop = scrollRef.current._container.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [balloons]);

  const getIndexSuffix = (i) => {
    switch (i) {
      case 0: return 'st';
      case 1: return 'nd';
      case 2: return 'rd';
      default: return 'th';
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 2,
        width: '100%',
        maxHeight: '97%',
        display: 'flex',
        justifyContent: 'center'
      }}
      ref={ref}
    >
      <Box
        sx={{
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'background.paper',
          boxShadow: '0 0 1px 0 rgb(0 0 0 / 70%), 0 3px 4px -2px rgb(0 0 0 / 50%)',
          borderBottomLeftRadius: 9,
          borderBottomRightRadius: 9
        }}
      >
        <Box mx={3} display="flex" flexDirection="row" alignItems="center">
          <Typography color="textPrimary" variant="h6" sx={{ my: 2, px: 5 }}>
            Hire Purchase Interest Rate Finder
          </Typography>
          <Box flexGrow={1} />
          <Box display="flex" justifyItems="right">
            <IconButton sx={{ width: 45, height: 45 }} onClick={handleClose}>
              <Close fontSize="medium" />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <Scrollbar
          ref={scrollRef}
          options={{ suppressScrollX: true }}
          style={{ maxHeight: 'calc(97% - 70px)', width: '100%' }}
        >
          <Grid container sx={{ pt: 3, px: 10 }} spacing={2}>
            <Grid item md={4} xs={12}>
              <Typography variant="body1" color="textSecondary">
                Borrowing Amount
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <NumberField
                size="small"
                sx={{ width: '100%' }}
                variant="outlined"
                onChange={handleBorrowedAmount}
                value={borrowedAmount}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography variant="body1" color="textSecondary">
                Number of Payments
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <NumberField
                size="small"
                sx={{ width: '100%' }}
                variant="outlined"
                onChange={handleNumberofPayments}
                value={numberofPayments}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography variant="body1" color="textSecondary">
                Repayment Amount
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <NumberField
                size="small"
                sx={{ width: '100%' }}
                variant="outlined"
                onChange={handleRepaymentAmount}
                value={repaymentAmount}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography variant="body1" color="textSecondary">
                Advance or Arrears
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <FormControl sx={{ width: '60%' }} size="small">
                <Select value={firstPay} onChange={handleFirstPay}>
                  {AOAOptions.map((aoaOpt) => (
                    <MenuItem value={aoaOpt.value} key={aoaOpt.value}>
                      <Typography color="textPrimary" variant="body2">
                        {aoaOpt.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography variant="body1" color="textSecondary">
                Period
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <FormControl sx={{ width: '60%' }} size="small">
                <Select value={period} onChange={handlePeriod}>
                  {PeriodOptions.map((periodOpt) => (
                    <MenuItem value={periodOpt.value} key={periodOpt.value}>
                      <Typography color="textPrimary" variant="body2">
                        {periodOpt.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {balloons.map((item) => (
            <Grid container sx={{ pt: 3, px: 10 }} spacing={2} key={`parent${item.index}`}>
              <Grid item md={4} xs={12}>
                <Typography variant="body1" color="textSecondary">
                  {item.index + 1}{getIndexSuffix(item.index)}&nbsp;Balloon if applicable
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <NumberField
                  size="small"
                  value={balloons[item.index].period}
                  sx={{ width: '100%' }}
                  variant="outlined"
                  onChange={(e) => {
                    const tmp = [...balloons];
                    tmp[item.index].period = e.target.value;
                    if (parseFloat(tmp[tmp.length - 1].period) > 0 && parseFloat(tmp[tmp.length - 1].amount)) {
                      tmp.push({ index: tmp.length, amount: '0', period: '0' });
                    }
                    setBalloons(tmp);
                  }}
                  placeholder="Period"
                />
                <NumberField
                  size="small"
                  value={balloons[item.index].amount}
                  sx={{ width: '100%', mt: 2 }}
                  variant="outlined"
                  onChange={(e) => {
                    const tmp = [...balloons];
                    tmp[item.index].amount = e.target.value;
                    if (parseFloat(tmp[tmp.length - 1].period) > 0 && parseFloat(tmp[tmp.length - 1].amount)) {
                      tmp.push({ index: tmp.length, amount: '0', period: '0' });
                    }
                    setBalloons(tmp);
                  }}
                  placeholder="Amount"
                />
              </Grid>
            </Grid>
          ))}
        </Scrollbar>
        <Divider sx={{ mt: 2 }} />
        <Box sx={{ width: '100%', my: 3, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            disabled={isCalculating}
            sx={{ width: '50%' }}
            onClick={handleCalculate}
          >
            {isCalculating && (
              <CircularProgress color="primary" size={20} sx={{ marginRight: '8px' }} />
            )}
            Calculate
          </Button>
        </Box>
        <Grid container sx={{ pb: 3, px: 10 }} spacing={2}>
          <Grid item md={4} xs={12}>
            <Typography variant="body1" color="textSecondary">
              Borrowing Rate
            </Typography>
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              type="text"
              size="small"
              sx={{ width: '100%' }}
              variant="outlined"
              value={borrowingRate}
              readOnly
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

export default HPCalculator;
