// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browse-sidebar {
  width: 340px;
  min-width: 340px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
}

.browse-sidebar-vertical-line {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border-right: 1px solid #e7e7e0;
}

.browse-sidebar .switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 22px 20px 20px !important;
  margin-right: 0 !important;
}
.new-chat-container{
  margin: 0 22px 20px 20px !important;
}

.btn-group button {
  width: 76px;
  height: 28px;
  border-radius: unset;
  box-sizing: border-box;
  background: white;
  color: #333;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  border: 1px solid #2895eb;
}

.btn-group .btn-chat {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.btn-group .btn-browse {
  /* border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; */
  border-radius: 0;
}

.btn-group .btn-scratch {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.btn-group .btn-reverse {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.btn-group button.active {
  background: #2895eb;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/browse/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;EACzB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,QAAQ;EACR,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,sCAAsC;EACtC,0BAA0B;AAC5B;AACA;EACE,mCAAmC;AACrC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,sBAAsB;EACtB,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE;qCACmC;EACnC,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE,6BAA6B;EAC7B,gCAAgC;AAClC;AACA;EACE,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".browse-sidebar {\n  width: 340px;\n  min-width: 340px;\n  background-color: #fafafa;\n  display: flex;\n  flex-direction: column;\n}\n\n.browse-sidebar-vertical-line {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  border-right: 1px solid #e7e7e0;\n}\n\n.browse-sidebar .switch-container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: 20px 22px 20px 20px !important;\n  margin-right: 0 !important;\n}\n.new-chat-container{\n  margin: 0 22px 20px 20px !important;\n}\n\n.btn-group button {\n  width: 76px;\n  height: 28px;\n  border-radius: unset;\n  box-sizing: border-box;\n  background: white;\n  color: #333;\n  font-size: 12px;\n  font-weight: 600;\n  line-height: normal;\n  border: 1px solid #2895eb;\n}\n\n.btn-group .btn-chat {\n  border-top-left-radius: 10px;\n  border-bottom-left-radius: 10px;\n}\n\n.btn-group .btn-browse {\n  /* border-top-right-radius: 10px;\n  border-bottom-right-radius: 10px; */\n  border-radius: 0;\n}\n\n.btn-group .btn-scratch {\n  border-top-right-radius: 0px;\n  border-bottom-right-radius: 0px;\n}\n\n.btn-group .btn-reverse {\n  border-top-right-radius: 10px;\n  border-bottom-right-radius: 10px;\n}\n.btn-group button.active {\n  background: #2895eb;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
