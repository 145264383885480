// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-page {
    margin-top: 120px;
}

.news-page .bg-news-banner {
    background-color: #e9ecef;
    margin: 5rem;
    padding: 2rem;
    border-radius: 1rem;
    text-align: center;
    margin-bottom: 1rem;
}

.news-page .bg-news-banner h6 {
    color: #2895eb;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 0.5rem;
}

.news-page .bg-news-banner h4 {
    font-size: 28px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 0.5rem;
}

.news-page .bg-news-banner p {
    margin: 0;
}

.flex-container-wrapper {
    margin: 5rem;
}

.flex-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 items per row */
    gap: 32px;
}

.flex-item {
    background-color: #fff;
    border: 1px solid #e9ecef;
    padding: 1rem 2rem;
}

.flex-item a {
    margin-bottom: 1rem;
    display: block;
}

@media (max-width: 1024px) {

    /* Tablets */
    .flex-container {
        grid-template-columns: repeat(2, 1fr);
        /* 2 items per row */
    }
}

@media (max-width: 768px) {

    /* Mobile devices */
    .flex-container {
        grid-template-columns: 1fr;
        /* 1 item per row */
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/layout/NewsPage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,qBAAqB;AACzB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,oBAAoB;IACpB,SAAS;AACb;;AAEA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,cAAc;AAClB;;AAEA;;IAEI,YAAY;IACZ;QACI,qCAAqC;QACrC,oBAAoB;IACxB;AACJ;;AAEA;;IAEI,mBAAmB;IACnB;QACI,0BAA0B;QAC1B,mBAAmB;IACvB;AACJ","sourcesContent":[".news-page {\n    margin-top: 120px;\n}\n\n.news-page .bg-news-banner {\n    background-color: #e9ecef;\n    margin: 5rem;\n    padding: 2rem;\n    border-radius: 1rem;\n    text-align: center;\n    margin-bottom: 1rem;\n}\n\n.news-page .bg-news-banner h6 {\n    color: #2895eb;\n    font-size: 16px;\n    font-weight: 600;\n    margin: 0;\n    margin-bottom: 0.5rem;\n}\n\n.news-page .bg-news-banner h4 {\n    font-size: 28px;\n    font-weight: 700;\n    margin: 0;\n    margin-bottom: 0.5rem;\n}\n\n.news-page .bg-news-banner p {\n    margin: 0;\n}\n\n.flex-container-wrapper {\n    margin: 5rem;\n}\n\n.flex-container {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    /* 3 items per row */\n    gap: 32px;\n}\n\n.flex-item {\n    background-color: #fff;\n    border: 1px solid #e9ecef;\n    padding: 1rem 2rem;\n}\n\n.flex-item a {\n    margin-bottom: 1rem;\n    display: block;\n}\n\n@media (max-width: 1024px) {\n\n    /* Tablets */\n    .flex-container {\n        grid-template-columns: repeat(2, 1fr);\n        /* 2 items per row */\n    }\n}\n\n@media (max-width: 768px) {\n\n    /* Mobile devices */\n    .flex-container {\n        grid-template-columns: 1fr;\n        /* 1 item per row */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
